import React from "react";
import RsaErrorIcon from "../assets/img/RsaErrorIcon.svg";
import RsaPendingIcon from "../assets/img/RsaPendingIcon.svg";
import RsaPublishedIcon from "../assets/img/RsaPublishedIcon.svg";
import RsaReadyToPublish from "../assets/img/RsaReadyToPublish.svg";
import RsaToolTipIcon from "../assets/img/RsaToolTipIcon.svg";
import New_Sticker from "../assets/img/new_sticker.svg";
import ShoppingIcon from "../assets/img/ShoppingCart_Icon.svg";
import SearchIcon from "../assets/img/Search_Icon.svg";
import AllInclusive from "../assets/img/AllInclusive.svg";
import BrandLogo from "../assets/img/lxr-guide-logo.svg";

import RemoveRedEye from "../assets/img/Eye_Icon.svg";
import TouchApp from "../assets/img/Click_Icon.svg";
import Payment from "../assets/img/Payment_Icon.svg";
import KPI from "../assets/img/KPI_Icon.svg";
import ShoppingCartOrders from "../assets/img/ShoppingCart_Orders_Icon.svg";
import DateRange from "../assets/img/DateRange_Icon.svg";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import link from "@mui/icons-material/Link";

import Revenue from "../assets/img/Revenue_Icon.svg";
import Percent from "../assets/img/Percent_Icon.svg";
import polygon from "../assets/img/no-aspect-ratio.svg";
import Cup from "../assets/img/cup.svg";
import CupRed from "../assets/img/cupRed.svg";
import CupYellow from "../assets/img/cupYellow.svg";
import CupGreen from "../assets/img/cupGreen.svg";
import Pointer from "../assets/img/pointer.svg";
import AutoApply from "../assets/img/autoapply.svg";
import AutoApplyWhite from "../assets/img/autoTaskPostThick.svg";
import AutoApplyUpdated from "../assets/img/AutoTaskUpdated.svg";

import WarningAlert from "../assets/img/warning.svg";
import WarningCircle from "../assets/img/warning-circle.svg";
import WarningErrorAlert from "../assets/img/waning-error.png";
import VerticalDivider from "../assets/img/vertical-divider.png";
import HorizonatDivider from "../assets/img/horizontal-divider.png";
import BlackCup from "../assets/img/black-cup.svg";
import HolidayPackageIcon from "../assets/img/holidayPackageIcon.svg";
import ConnectionIcon from "../assets/img/connectionVector.svg";
import Calendar from "../assets/img/Calendar.svg";
import Rectangle from "../assets/img/Rectangle.svg";
import GAmalePersona from "../assets/img/GAmalePersona.png";
import GAfemalePersona from "../assets/img/GAfemalePersona.png";
import GoogleAnalyticsDark from "../assets/img/GoogleAnalyticsIcondark.svg";
import check from "../assets/img/check.png";
import cross from "../assets/img/cross.png";
import GoogleAnalyticsLight from "../assets/img/GoogleAnalyticsIconlight.svg";
import upwardArrow from "../assets/img/upwardArrow.svg";
import downwardArrow from "../assets/img/downwardArrow.svg";
// import Money from "assets/img/money.svg"
// import Percentage from "../assets/img/percentage.svg"

import adgrpIcon from "../assets/img/adgrp-icon.svg";
import camIcon from "../assets/img/camp-icon.svg";
import taskIcon from "../assets/img/task-icon.svg";
import keywordIcon from "../assets/img/keyword-icon.svg";
import productGrpIcon from "../assets/img/productGrp.svg";
import locationOn from "../assets/img/locationOn.svg";
import linkIcon from "../assets/img/Link_Icon.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DoneIcon from "@mui/icons-material/Done";
import logo from "../assets/img/lxr-guide-logo_1.png";
import EmptyStar from "../assets/img/empty_Star_icon.svg";
import FilledStar from "../assets/img/filled_star_icon.svg";
import Feedback from "@mui/icons-material/Feedback";
import adcopyIcon from "../assets/img/adcopy_icon.svg";
import ProfessionalService from "../assets/img/professional-service.svg";
import StarterService from "../assets/img/starter-service.svg";
import PremierService from "../assets/img/premier-service.svg";
import dot from "../assets/img/dot_icon.svg";
import dot_black from "../assets/img/dot_icon.svg";
import dot_grey from "../assets/img/grey_dot.svg";
import blueDot from "../assets/img/blueDot.svg";
import RemoveIcon from "@mui/icons-material/Remove.js";
import AddIcon from "@mui/icons-material/Add";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import MinimizeIcon from "../assets/img/minimize.svg";
import MaximizeIcon from "../assets/img/maximize.svg";
import bgSlide from "../assets/img/request-bg-slide.svg";
import smartReporting from "../assets/img/smartReporting.svg";
import campaignRecommendations from "../assets/img/campaignRecommendations.svg";
import timeSaving from "../assets/img/timeSaving.svg";
import carousel1 from "../assets/img/Carousel-1.png";
import Artboard1 from "../assets/img/Artboard-1.png";
import Artboard2 from "../assets/img/Artboard-2.png";
import Artboard3 from "../assets/img/Artboard-3.png";
import WhiteLogo from "../assets/img/white-logo.svg";
import Envelope from "../assets/img/envolpe.png";
import Phone from "../assets/img/Phone.png";
import Location from "../assets/img/location.png";
import checked from "../assets/img/checked.svg";
import unChecked from "../assets/img/unChecked.svg";
import checkedSquare from "../assets/img/checked1.svg";
import whiteLogo1 from "../assets/img/lxr-guide-full-white-logo.png";
import Warning from "@mui/icons-material/Warning.js";
import Cached from "@mui/icons-material/Cached.js";
import Create from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import QuestionMarkFilled from "@mui/icons-material/Help";
import BingIcon from "../assets/img/bing_small.svg";
import GoogleIcon from "../assets/img/google_small.svg";
import Edit from "@mui/icons-material/Edit";
import Check from "@mui/icons-material/Check";
import Delete from "@mui/icons-material/Delete";
import Domain from "@mui/icons-material/Domain";
import GoogleSearch from "../assets/img/google-search.svg";
import Adcopy from "../assets/img/adcopy.svg";
import User from "../assets/img/user.svg";
import Users from "../assets/img/users.svg";
import Dashboard from "@mui/icons-material/Dashboard";
import Info from "@mui/icons-material/Info";
import OpportunityHand1 from "../assets/img/Opportunity-img-1.svg";
import Block1 from "../assets/img/Block1.png";
import Block2 from "../assets/img/Block2.png";
import Block3 from "../assets/img/Block3.png";
// import LoadingMeter from "../assets/img/loader-meter.gif";
import Loading_1 from "../assets/img/loading-1.svg";
import Loading_3 from "../assets/img/loading-3.svg";
import Loading_6 from "../assets/img/loading-6.svg";
import Loading_7 from "../assets/img/loading-7.svg";
import HolidayCal from "../assets/img/holiday-calendar.svg";
import GaugeIcon from "../assets/img/gaugeIcon.svg";
import GroupHand from "../assets/img/group_hand.svg";
import GaugeIcon2 from "../assets/img/gaugeIcon-2.svg";
import GroupHand2 from "../assets/img/group_hand-2.svg";
import VectorBg from "../assets/img/vector_bg.svg";
import Client_1 from "../assets/img/client_1.png";
import Client_1_icon from "../assets/img/client_1_brand_icon.svg";
import Leaf_Orange from "../assets/img/leaf-orange.svg";
import MobDesktopStats from "../assets/img/mob-desktop-stats.svg";
import MobileDesktopStatsNew from "../assets/img/MobileDesktopStatsNew.svg";
import GroupUsers from "../assets/img/grp_users.svg";
import GroupStatsNew from "../assets/img/GroupStatsNew.svg";
import LandingPageMetric from "../assets/img/landingPageScore.svg";
import LandingPageMetricNew from "../assets/img/LandingPageMetricNew.svg";
import Negativekeywords from "../assets/img/negative-keywords.svg";
import NegativeKeywordsNew from "../assets/img/NegativeKeywordsNew.svg";
import WastedSpend from "../assets/img/wastedSpend.svg";
import WastedSpendMetricNew from "../assets/img/WastedSpendMetricNew.svg";
import AccountStructure from "../assets/img/accountStructure.svg";
import AccountMetricScoreNew from "../assets/img/AccountMetricScoreNew.svg";
import SafeAndSecure from "../assets/img/safeAndSecure.svg";
import ChristmasBg from "../assets/img/christmas-bg.png";
import QuoteLeft from "../assets/img/quote-left.svg";
import Time from "../assets/img/time.svg";
import HandCircle from "../assets/img/hand-circle.svg";
import HandCircleSingle from "../assets/img/hand-circle-single.svg";
import TaskHammer from "../assets/img/task-hammer.svg";
import ShoppingCart from "../assets/img/shopping-cart.svg";
import GooglePartner from "../assets/img/google_partner_icon.png";
import MicrosoftIcon from "../assets/img/microsoft_icon.png";
import Meter from "../assets/img/meter.svg";
import BookCircle from "../assets/img/book-circle.svg";
import ShoppingCartCircle from "../assets/img/shopping-cart-cricle.svg";
import BlogCircle from "../assets/img/blog-circle.svg";
import KeyCircle from "../assets/img/key-circle.svg";
import LocCircle from "../assets/img/loc-circle.svg";
import KeywordScore from "../assets/img/keywordScore.png";
import KeywordScore1 from "../assets/img/KeywordScore1.png";
import LinkValidityScore from "../assets/img/linkValidityScore.png";
import LinkValidityScore1 from "../assets/img/linkValidityScore1.png";
import WebsiteTechinicalScore from "../assets/img/WebsiteTechinicalScore.svg";
import SocialMediaScore from "../assets/img/SocialMediaScore.svg";
import WebsiteTrafficAnalysis from "../assets/img/WebsiteTrafficAnalysis.svg";
import LinkValidityScoreNew from "../assets/img/LinkValidityScoreNew.svg";
import MobileCompatibility from "../assets/img/mobileCompatibility.png";
import MobileCompatibility1 from "../assets/img/MobileCompatibility1.png";
import MobileCompatibilityNew from "../assets/img/MobileCompatibilityNew.svg";
import OnPageScore from "../assets/img/onPageScore.png";
import OnPageScore1 from "../assets/img/onPageScore1.png";
import OnPageScoreNew from "../assets/img/newOnPageScore.svg";
import PageLoadSpeedScore from "../assets/img/pageLoadSpeedScore.png";
import PageLoadSpeedScore1 from "../assets/img/pageLoadSpeedScore1.png";
import TechnicalVisibility from "../assets/img/technicalVisibility.png";
import TechnicalVisibility1 from "../assets/img/technicalVisibility1.png";
import MobileFriendly from "../assets/img/mobileFriendly.svg";
import MobileFriendlyNot from "../assets/img/mobileFriendlyNot.svg";
import MobileViewPort from "../assets/img/mobileViewPort.svg";
import MobileViewPortNot from "../assets/img/mobileViewPortNot.svg";
import Client_2 from "../assets/img/client2.png";
import Client_3 from "../assets/img/client3.png";
import Client_4 from "../assets/img/client4.png";
import Client_5 from "../assets/img/client5.png";
import Qoute_left from "../assets/img/qoute.png";
import CircleCheckCustom from "../assets/img/circle-check-custom.svg";
import ActiveStep from "../assets/img/active-step.svg";
import BlueCircle from "../assets/img/blueCircle.svg";
import GrayCircle from "../assets/img/grayCircle.svg";
import YellowCircle from "../assets/img/yellowCircle.svg";
import GreenCircle from "../assets/img/greenCircle.svg";
import bingSig from "../assets/img/bing_sig.png";
import fbSig from "../assets/img/fb_sig.png";
import googleSig from "../assets/img/Google-Premier-Partner-1.png";
import isoSig from "../assets/img/iso_sig.png";
import upsSig from "../assets/img/ups_sig.png";

import NewIcon from "../assets/img/new_icon.png";
import recommendationIcon from "../assets/img/recommendation_icon.png";
import Logo from "../assets/img/lxr-guide-logo.png";
import GoogleIcon1 from "../assets/img/google-icon-social.svg";
import BingIcon1 from "../assets/img/bing-icon.svg";
import SEOScoreCardIcon from "../assets/img/SEOScoreCard.svg";
import onDemandIcon from "../assets/img/on-demand-icon.svg";
import SettingIcon from "../assets/img/Settings-icon.svg";
import HelpIcon from "../assets/img/Help-center-icon.svg";
import DownArrow from "../assets/img/downArrow.svg";
import Light from "../assets/img/light.svg";
import Google from "../assets/img/google-icon.svg";
import Bing from "../assets/img/bing-icon.svg";
import SEO from "../assets/img/SEO-icon.svg";
import ProfileIcon from "../assets/img/profile.svg";
import TaskCompletedIcon from "../assets/img/TasksCompleted.svg";
import NoTasksIcon from "../assets/img/NoTasks.svg";
// import Dashboard from "../assets/img/dashboard.svg";
// import Tasks from "../assets/img/img/Tasks.js";
import {
  Tasks,
  Dashboard1,
  Reports,
  ActivityReports,
  Square,
} from "../assets/img/svg/index.js";
// import Reports from "../assets/img/reports.svg";
// import ActivityReports from "../assets/img/reports.svg";
import { Help } from "@mui/icons-material";
import upArrow from "../assets/img/up-arrow.svg";
import upBigArrow from "../assets/img/upBigArrow.svg";
import downArrow from "../assets/img/down-arrow.svg";
import performance from "../assets/img/perfromace.svg";
import pendingTask from "../assets/img/pending-task.svg";
import xLogo from "../assets/img/xIcon.svg";
import brand from "../assets/img/brand.png";
import account from "../assets/img/account-symbol.svg";
// import leftArrow from "../assets/img/left-arrow.svg";
// import rightArrow from "../assets/img/right-arrow.svg";
import leftArrow from "../assets/img/left-arrow-stroke.svg";
import rightArrow from "../assets/img/right-arrow-stroke.svg";
import rightArrowTask from "../assets/img/task-right-arrow.svg";
import MobileMenu from "../assets/img/menu_icon.svg";
import MobileMenu1 from "../assets/img/menu_icon1.svg";
import BrandMobile from "../assets/img/brand-mobile.png";
import BrandMobile1 from "../assets/img/brand-mobile-1.png";
import CloseWindow from "../assets/img/close-btn.svg";
import MicroSoftNewIcon from "../assets/img/microsoftImage.svg";
import DropDownArrow from "../assets/img/dropDownArrow.svg";
import CheckCircle2 from "../assets/img/check_circle.svg";
import CheckCircleEmpty from "../assets/img/checkCircleEmpty.svg";
import Mobile from "../assets/img/Mobile.svg";
import SpeedIcon from "../assets/img/speedIcon.svg";
import backIcon from "../assets/img/left-icon.svg";
import Close from "../assets/img/close.svg";
import InfoIcon from "../assets/img/info_icon.svg";
import Ad from "../assets/img/Ad.svg";
import OrangeCircleChecked from "../assets/img/orange-checked-cricle.svg";
import UnCheckedNew from "../assets/img/unchecked-icon.svg";
import leftArrowB from "../assets/img/left-arrow-b.svg";
import downBig from "../assets/img/down-big.svg";
import accountDone from "../assets/img/jumpstart-2.png";
import accountLive from "../assets/img/jumpstart-3.png";
import displayStand from "../assets/img/display-stand.png";
import greenDot from "../assets/img/green-dot.svg";
import CardTypeDiscover from "../assets/img/cardType_discover.svg";
import CardTypeJcb from "../assets/img/cardType_jcb.svg";
import CardTypeDinersClub from "../assets/img/cardType_diners_club.svg";
import CardTypeMaestro from "../assets/img/cardType_Maestro.svg";
import CardTypeMasterCard from "../assets/img/cardType_MasterCard.svg";
import CardTypeVisa from "../assets/img/cardType_Visa-Logo.svg";
import CardTypeAmex from "../assets/img/cardType_amex_american_express.svg";
import notificationIcon from "../assets/img/notification-bell.svg";
import notificationIcon1 from "../assets/img/celebrate-icon.svg";
import notificationIcon2 from "../assets/img/thumb_up.svg";
import notificationIcon3 from "../assets/img/warning-icon.svg";
import graderHomeIcon from "../assets/img/graderHomeIcon.png";
import graderHomePageIcon from "../assets/img/graderHomePageIcon.svg";
import seoGraderHomeIcon from "../assets/img/seoGraderHomeIcon.png";
import seoGraderHomePageIcon from "../assets/img/seoGraderHomePageIcon.svg";
import helpBg from "../assets/img/help-bg.svg";
import userCEO from "../assets/img/user-ceo.svg";
import userDoller from "../assets/img/user-doller.svg";
import userSpeaker from "../assets/img/user-speaker.svg";
import circleDot from "../assets/img/circle-dote.svg";
import thomesEllis from "../assets/img/thomesEllies.png";
import priesterspecan from "../assets/img/priesterspecan.png";
import overtenkaccountgraded from "../assets/img/overtenkaccountgraded.svg";
import aboutNetelixir from "../assets/img/aboutNetelixir.svg";
import aboutUs from "../assets/img/about-us-image.svg";
import features from "../assets/img/features-page.svg";

// import overtenkaccountgraded from '../assets/img/overtenkaccountgraded.svg'
import paidsearchmanagement from "../assets/img/paidsearchmanagement.svg";
import microsoftAdswh from "../assets/img/microsoftAds-wh.png";
import upsblackandwhitelogo from "../assets/img/ups-black-and-white-logo-png-transparent-11.png";
import googlepartnerwh from "../assets/img/google-partner-wh.png";
import lxrguidegrowthmodelhp from "../assets/img/lxrguidegrowthmodel-hp.png";
import coffeecowlogo from "../assets/img/coffeecow.png";
import coffeecowowner from "../assets/img/gray-coffeecow.png";
import requestDemo from "../assets/img/request-demo.svg";
// import requestPreview from "../assets/img/demo-preview.png";
import orangeReactCheck from "../assets/img/orangeReactChecked.svg";
import rectangle from "../assets/img/rectangle-193.svg";
import cheersCollection from "../assets/img/cheerscollection.png";
import IntenziWebsiteLogo from "../assets/img/IntenziWebsiteLogo.svg";
import PromesaWebsiteLogo from "../assets/img/PromesaWebsiteLogo.svg";
import TeacherDirectWebsiteLogo from "../assets/img/TeacherDirectWebsiteLogo.svg";
import ProucroWebsiteLogo from "../assets/img/ProucroWebsiteLogo.svg";
import DemoulinWebsiteLogo from "../assets/img/DemoulinWebsiteLogo.svg";
import SwanCreekCandleLogo from "../assets/img/SwanCreekCandleLogo.svg";
import PlanetSXSWebsiteLogo from "../assets/img/PlanetSXSWebsiteLogo.svg";
import LuxuryMetalsWebsiteLogo from "../assets/img/LuxuryMetalsWebsiteLogo.svg";
import OopsyDaisyWebsiteLogo from "../assets/img/OopsyDaisyWebsiteLogo.svg";
import GreenBoxArtWebsiteLogo from "../assets/img/GreenBoxArtWebsiteLogo.svg";
import ATPWebsiteLogo from "../assets/img/ATPWebsiteLogo.svg";
import FeedYourSoulWebsiteLogo from "../assets/img/FeedYourSoulWebsiteLogo.svg";
import CulinaryClassicsLogo from "../assets/img/CulinaryClassicsLogo.svg";
import PureCoinLogo from "../assets/img/PureCoinLogo.svg";
import FullForceDieselLogo from "../assets/img/FullForceDieselLogo.svg";
import FixtureDisplaysLogo from "../assets/img/FixtureDisplaysLogo.svg";
import luxuryMetals from "../assets/img/Luxury-Metals.png";
import swancreek from "../assets/img/swancreek.png";
import synder from "../assets/img/synder.png";
import gooldenBarrel from "../assets/img/goldenbarrelimage.png";
import feedYourSoul from "../assets/img/feedyoursoul.png";
import netelixirPoweredby from "../assets/img/poweredbynetelixirwhite.svg";
import netelixirlogonewpoweredby from "../assets/img/netelixirlogonewpoweredby.svg";
import Lxrguidewhite from "../assets/img/lxrguide-wh.png";
import bestOffer from "../assets/img/bestoffer.png";
import microsoftLogoNew from "../assets/img/Microsoft_logo.svg";
import adwordsNewLogo from "../assets/img/AdWordsNew.svg";
import featuresAndBenefitsOnline from "../assets/img/featuresAndBenefitsOnline.png";
import featuresAndBenefitsAdvantage from "../assets/img/dashboard-report.jpg";
// import featuresAndBenefitsTransparency from '../assets/img/featuresAndBenefitsTransparency.png';
// import featuresAndBenefitsTransparency from '../assets/img/dashboard-report.jpg';
import featuresAndBenefitsTransparency from "../assets/img/featuresAndBenefitsTransparency.png";
import featuresAndBenefitsChanges from "../assets/img/dashboard-report.jpg";
import errorIcon from "../assets/img/error.png";
import requestPreview from "../assets/img/gif/lxrguide-demo.gif";
import microsoftPartnerNew from "../assets/img/microsoftpartnernew.png";
import upsReadyPartnerLogoNew from "../assets/img/upsreadypartnerlogonew.png";
import upsNewlogofooter from "../assets/img/upsnewlogofooter.png";
import microsoftAdsPartnerlogonewftr from "../assets/img/MicrosoftAdspartnernewlogo.png";
import upsWithBlackBorder from "../assets/img/upswithblckborder.png";
import guidedwalkthrough from "../assets/img/guidedwalkthrough.png";
import shopifyLXR from "../assets/img/shopify.svg";
import bigCommerceLXR from "../assets/img/bigCommerce.svg";
import wooCommerceLXR from "../assets/img/wooCommerce.svg";
import speaker from "../assets/img/speaker.svg";
import setting1 from "../assets/img/setting1.svg";
import mobIcon from "../assets/img/mobIcon.svg";
import insights1 from "../assets/img/insights1.svg";
import saveCostForecast from "../assets/img/savecostForecast.svg";
import webDev from "../assets/img/webDevelopment.svg";
import webDev_1 from "../assets/img/webDevelopment_1.svg";
import holidayBoost from "../assets/img/boost_holiday_rocket.svg";
import tour from "../assets/img/tour.svg";
import subscription from "../assets/img/subscription.svg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LineSeperater from "../assets/img/LineSeperater.svg";
import NetElixirLogo from "../assets/img/NetelixirLogo.svg";

import pvOneDotofthree from "../assets/img/pVOneDotofthree.svg";
import pvTwoDotofthree from "../assets/img/pVTwoOutofThree.svg";
import pvThreeDotofthree from "../assets/img/pVThreeOfThree.svg";
import minmax from "../assets/img/minmax.svg";
import googleanalyticsbanner from "../assets/img/googleAnalyticsBanner.png";
import whs_logo from "../assets/img/website_health_scorecard/WHS_Logo.svg";
import whs_logo_2 from "../assets/img/website_health_scorecard/WHS_Logo_2.svg";
import WHSExternalLink from "../assets/img/website_health_scorecard/WHSExternalLink.svg";
import WHSRefresh from "../assets/img/website_health_scorecard/WHSRefresh.svg";
import WHSBulb from "../assets/img/website_health_scorecard/WHSBulb.svg";
import WHSGoodOverallScore from "../assets/img/website_health_scorecard/WHSGoodOverallScore.svg";
import WHSConsultation from "../assets/img/website_health_scorecard/WHSConsultation.svg";
import WHSDiscoverabilityInfo from "../assets/img/website_health_scorecard/WHSDiscoverabilityInfo.svg";
import WHSPerformanceInfo from "../assets/img/website_health_scorecard/WHSPerformanceInfo.svg";
import WHSInfo from "../assets/img/website_health_scorecard/WHSInfo.svg";
import WHSInstagram from "../assets/img/website_health_scorecard/WHSInstagram.svg";
import WHSFacebook from "../assets/img/website_health_scorecard/WHSFacebook.svg";
import WHSYoutube from "../assets/img/website_health_scorecard/WHSYoutube.svg";
import WHSLinkedIn from "../assets/img/website_health_scorecard/WHSLinkedIn.svg";
import WHSTwitter from "../assets/img/website_health_scorecard/WHSTwitter.svg";
import WHSHorizontalLine from "../assets/img/website_health_scorecard/WHSHorizontalLine.svg";
import WHSChecked from "../assets/img/website_health_scorecard/WHSChecked.svg";
import WHSUnchecked from "../assets/img/website_health_scorecard/WHSUnchecked.svg";
import WHSAccessibilityInfo from "../assets/img/website_health_scorecard/WHSAccessibilityInfo.svg";
import WHSSecurityInfo from "../assets/img/website_health_scorecard/WHSSecurityInfo.svg";
import WHSVerticalLine from "../assets/img/website_health_scorecard/WHSVerticalLine.svg";
import WHSErrorBulb from "../assets/img/website_health_scorecard/WHSErrorBulb.svg";
import WHSPoorOverallScore from "../assets/img/website_health_scorecard/WHSPoorOverallScore.svg";
import WHSPoint1 from "../assets/img/website_health_scorecard/WHSPoint1.svg";
import WHSPoint2 from "../assets/img/website_health_scorecard/WHSPoint2.svg";
import WHSPoint3 from "../assets/img/website_health_scorecard/WHSPoint3.svg";
import WHSDescription from "../assets/img/website_health_scorecard/WHSDescription.svg";
// Connection Page
import GoogleAds from "../assets/img/connection_page/Google_Ads.svg";
import BingAds from "../assets/img/connection_page/Bing_Ads.svg";
import GMC from "../assets/img/connection_page/GMC.svg";
import ShoppingFeed from "../assets/img/connection_page/Shopping_Feed.svg";
import SegregationLine from "../assets/img/connection_page/Vertical_Line.svg";
import BigCommerce from "../assets/img/connection_page/BigCommerce.png";
import WooCommerce from "../assets/img/connection_page/WooCommerce.png";
import Shopify from "../assets/img/connection_page/Shopify.png";
import GoogleSearchConsole from "../assets/img/connection_page/Google_Search_Console.svg";

import PromocodeClear from "../assets/img/promocodeClear.svg";
//WHS Homepage Icons
import ExternalAccessibility from "../assets/img/website_health_scorecard/ExternalAccessibility.svg";
import ExternalDiscoverability from "../assets/img/website_health_scorecard/ExternalDiscoverability.svg";
import ExternalPerformance from "../assets/img/website_health_scorecard/ExternalPerformance.svg";
import ExternalSecurity from "../assets/img/website_health_scorecard/ExternalSecurity.svg";
import ExternalWHSIcon from "../assets/img/website_health_scorecard/ExternalWHSIcon.svg";

//Revamped Jumpstart Icons
import JumpstartLogin from "../assets/img/jumpstart_revamped/JumpstartLogin.jpg";
import LxrguideLogo from "../assets/img/jumpstart_revamped/LxrguideLogo.svg";
import SignIn from "../assets/img/jumpstart_revamped/SignIn.svg";
import AccountsMenu from "../assets/img/jumpstart_revamped/Accounts.svg";
import UsersMenu from "../assets/img/jumpstart_revamped/Users.svg";
import ExcelUpload from "../assets/img/jumpstart_revamped/ExcelUpload.svg";
import ExcelDownload from "../assets/img/jumpstart_revamped/ExcelDownload.svg";
import FrameworkCompleted from "../assets/img/jumpstart_revamped/FrameworkCompleted.svg";
import HorizontalDashedLine from "../assets/img/jumpstart_revamped/HorizontalDashedLine.svg";
import JsOpenIcon from "../assets/img/jumpstart_revamped/Open.svg";
import JsCloseIcon from "../assets/img/jumpstart_revamped/Close.svg";
import JsCampaignIcon from "../assets/img/jumpstart_revamped/CampaignIcon.svg";
import JsAdgroupIcon from "../assets/img/jumpstart_revamped/AdgroupIcon.svg";
import JsBusinessIcon from "../assets/img/jumpstart_revamped/BusinessNameIcon.svg";
import CampaignStructureCompleted from "../assets/img/jumpstart_revamped/CampaignStructureCompleted.svg";
import PasswordResetEmail from "../assets/img/jumpstart_revamped/PasswordResetEmail.svg";
import PasswordChanged from "../assets/img/jumpstart_revamped/PasswordChanged.svg";
import JumpstartLogo from "../assets/img/jumpstart_revamped/Jumpstart.svg";
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
const icons = {
  PendingIcon: ({sx}) => <HourglassEmptyOutlinedIcon sx={{border:"1px solid #f58120", padding: "2px", borderRadius: "50%", background:"#f58120", color: "#fff", ...sx}}/>,
  Shopping: <img src={ShoppingIcon} alt={"Shopping"} />,
  Search: <img src={SearchIcon} alt={"Search"} />,
  All: <img src={AllInclusive} alt={"AllInclusive"} />,

  Views: <img src={RemoveRedEye} alt={"Views"} />,
  Click: <img src={TouchApp} alt={"Clicks"} />,
  Ctr: <img src={Percent} alt={"CTR"} />,
  Orders: <img src={ShoppingCartOrders} alt={"Orders"} />,
  Spend: <img src={Payment} alt={"Spend"} />,
  KPI: <img src={KPI} alt={"KPI"} />,
  Revenue: <img src={Revenue} alt={"Revenue"} />,
  Calendar: <img src={DateRange} alt={"Calender"} />,
  LocationOn: <img src={locationOn} alt={"Location"} />,
  polygon: <img src={polygon} alt={"Title"} />,
  FilledStar: (
    <img
      src={FilledStar}
      alt={"FilledStar"}
      style={{ paddingLeft: 4, paddingRight: 4 }}
    />
  ),
  EmptyStar: (
    <img
      src={EmptyStar}
      alt={"EmptyStar"}
      style={{ paddingLeft: 4, paddingRight: 4 }}
    />
  ),
  BrandLogo: (
    <img src={BrandLogo} alt={"LXRGuide"} style={{ height: 27, width: 196 }} />
  ),
  AccessTimeIcon: <img src={AccessTimeIcon} alt={"AccessTimeIcon"} />,
  Task: <img src={taskIcon} alt={"Task"} />,
  TaskType: <img src={taskIcon} alt={"Task"} />,
  Campaign: <img src={camIcon} alt={"Campaign"} />,
  AdGroup: <img src={adgrpIcon} alt={"Ad Group"} />,
  Keyword: <img src={keywordIcon} alt={"Keyword"} />,
  Link: <img src={linkIcon} alt={"Link"} />,
  Adcopy: <img src={adcopyIcon} alt={"Adcopy"} />,
  Warning: <Warning />,
  WarniloginngAlert: <img src={WarningAlert} alt={"Warning"} />,

  ProductGroup: <img src={productGrpIcon} alt={"ProductGroup"} />,
  ArrowForward: <ArrowForwardIcon />,
  CheckCircle: CheckCircle,
  Done: DoneIcon,
  Feedback: Feedback,
  link: link,
  LXRGudieLogo: <img src={logo} width="250" height="30" alt={"LXRGuide"} />,
  StarterService: <img src={StarterService} />,
  ProfessionalService: <img src={ProfessionalService} />,
  PremierService: <img src={PremierService} />,
  dot: <img src={dot} />,
  blueDot: <img src={blueDot} alt={"Dot"} />,
  dotBlack: <img src={blueDot} alt={"Dot"} />,
  dotGrey: <img src={dot_grey} alt={"Dot"} />,
  Add: AddIcon,
  Remove: RemoveIcon,
  ChevronArrowLeft: <ChevronLeft />,
  minimize: <img src={MinimizeIcon} />,
  maximize: <img src={MaximizeIcon} />,
  bgSlide: <img src={bgSlide} alt={"paidSearchBg"} />,
  smartReport: <img src={smartReporting} />,
  campaignRecommendations: <img src={campaignRecommendations} />,
  timeSaving: <img src={timeSaving} />,
  download: <i className="fa fa-download" aria-hidden="true"></i>,
  light: <i className="fa fa-lightbulb-o" aria-hidden="true"></i>,
  Artboard1: <img src={Artboard1} alt={"Dashboard View"} />,
  Artboard2: <img src={Artboard2} alt={"Reports View"} />,
  Artboard3: <img src={Artboard3} alt={"Task View"} />,
  WhiteLogo: <img src={WhiteLogo} alt={"Brand Logo"} />,
  WhiteLogo1: (
    <img
      src={whiteLogo1}
      alt={"Brand Logo"}
      style={{ height: 30, width: 250 }}
    />
  ),
  envelopeResizable: (width, height) => (
    <img
      src={Envelope}
      alt={"Email"}
      style={{ height: height, width: width }}
    />
  ),
  envelope: <img src={Envelope} alt={"Email"} />,
  phoneResizable: (width, height) => (
    <img src={Phone} alt={"Phone"} style={{ height: height, width: width }} />
  ),
  domainResizable: (fontSize) => <Domain style={{ fontSize: fontSize }} />,
  location: <img src={Location} alt={"Location"} />,
  /*envelope: <i className="fa fa-envelope" aria-hidden="true"></i>,
    phone: <i className="fa fa-phone" aria-hidden="true"></i>,
    location: <i className="fa fa-map-marker" aria-hidden="true"></i>,*/
  phone: <img src={Phone} alt={"Phone"} />,
  facebook: <i className="fa fa-facebook-official" aria-hidden="true"></i>,
  twitter: <i className="fa fa-twitter-square" aria-hidden="true"></i>,
  twitter1: <i className="fa fa-twitter" aria-hidden="true"></i>,
  linkedIn: <i className="fa fa-linkedin-square" aria-hidden="true"></i>,
  checkedRadio: <img src={checked} alt={"checked"} />,
  checked: <img src={checkedSquare} alt={"checked"} />,
  checkedCircle: <i className="fa fa-check-circle" aria-hidden="true"></i>,
  closeCircle: <i className="fa fa-times-circle" aria-hidden="true"></i>,
  calenderChecked: (
    <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
  ),
  unCheckedRadio: <img src={unChecked} alt={"unchecked"} />,
  unChecked: <i className="fa fa-square-o" aria-hidden="true"></i>,
  googleRecommendation: (
    <img src={GoogleSearch} alt={"Google Recommendation"} />
  ),
  create: Create,
  close: CloseIcon,
  help: QuestionMarkFilled,
  googleSmall: (
    <img src={GoogleIcon} alt={"Google Ads"} style={{ width: 18 }} />
  ),
  bingSmall: <img src={BingIcon} alt={"Bing"} />,
  /*   user: <i className="fa fa-user" aria-hidden="true"></i>,
       users: <i className="fa fa-users" aria-hidden="true"></i>,*/
  user: <img src={User} alt={"User"} />,
  users: <img src={Users} alt={"Agency User"} />,
  Edit: Edit,
  Check: Check,
  Delete: Delete,
  Dashboard: <Dashboard />,
  adcopy: <img src={Adcopy} alt={"ad Copy"} />,
  clipBoard: <i className="fa fa-clipboard" aria-hidden="true"></i>,
  Info: Info,
  tasks: <i className="fa fa-tasks" aria-hidden="true"></i>,

  optimisedHand: <img src={OpportunityHand1} alt={"Optimised Account"} />,
  paperPlane: <i className="fa fa-paper-plane" aria-hidden="true"></i>,
  client_1: Client_1,
  client_1_dummy: <img src={Client_1} alt={"LxrGuide client"} />,
  client_1_icon: <img src={Client_1_icon} alt={"Client"} />,
  leafOrange: <img src={Leaf_Orange} alt={"Leaf"} />,
  mobDesktopStats: <img src={MobDesktopStats} alt={"Stats"} />,
  mobileDesktopStatsNew: (w) => (
    <img src={MobileDesktopStatsNew} alt={"Stats"} width={w} />
  ),
  groupUsers: <img src={GroupUsers} alt={"Impression Share Icons"} />,
  groupStatsNew: (w) => (
    <img src={GroupStatsNew} alt={"Impression Share Icons"} width={w} />
  ),
  landingPageMetric: <img src={LandingPageMetric} alt={"Landing Page Score"} />,
  landingPageMetricNew: (w) => (
    <img src={LandingPageMetricNew} alt={"Landing Page Score"} width={w} />
  ),
  negativeKeywords: <img src={Negativekeywords} alt={"Negative keywords"} />,
  negativeKeywordsNew: (w) => (
    <img src={NegativeKeywordsNew} alt={"Negative keywords"} width={w} />
  ),
  wastedSpend: <img src={WastedSpend} alt={"Wasted Spend Score"} />,
  wastedSpendMetricNew: (w) => (
    <img src={WastedSpendMetricNew} alt={"Wasted Spend Score"} width={w} />
  ),
  accountStructure: (
    <img src={AccountStructure} alt={"Account Structure Score"} />
  ),
  accountMetricScoreNew: (w) => (
    <img
      src={AccountMetricScoreNew}
      alt={"Account Structure Score"}
      width={w}
    />
  ),
  safeAndSecure: <img src={SafeAndSecure} alt={"Safe and Secure"} />,
  christmasBg: <img src={ChristmasBg} alt={"Christmas"} />,
  // loadingMeter: <img src={LoadingMeter} alt={"loading"}/>,
  loading_1_Resizable: (w, h) => (
    <img src={Loading_1} alt={"loading"} height={h} width={w} />
  ),
  loading_3_Resizable: (w, h) => (
    <img src={Loading_3} alt={"loading"} height={h} width={w} />
  ),
  loading_6_Resizable: (w, h) => (
    <img src={Loading_6} alt={"loading"} height={h} width={w} />
  ),
  loading_7_Resizable: (w, h) => (
    <img src={Loading_7} alt={"loading"} height={h} width={w} />
  ),
  holidayCal: <img src={HolidayCal} alt={"Holiday calendar"} />,
  signup: <i className={"fa fa-sign-in"} alt={"Grade Grader"} />,
  gaugeSm: (w, h) => (
    <img src={GaugeIcon} alt={"Gauge Icon"} height={h} width={w} />
  ),
  groupHand: (w, h) => (
    <img src={GroupHand} alt={"Accounts"} height={h} width={w} />
  ),
  gaugeSm2: (w, h) => (
    <img src={GaugeIcon2} alt={"Gauge Icon"} height={h} width={w} />
  ),
  groupHand2: (w, h) => (
    <img src={GroupHand2} alt={"Accounts"} height={h} width={w} />
  ),
  vectorBg: (w, h) => (
    <img src={VectorBg} alt={"Accounts"} height={h} width={w} />
  ),
  quoteLeft: <img src={QuoteLeft} alt={"Quote Left"} />,
  time: <img src={Time} alt={"Time"} />,
  handCircle: <img src={HandCircle} alt={"Hand"} />,
  handCircleSingle: <img src={HandCircleSingle} alt={"Hand"} />,
  taskHammer: <img src={TaskHammer} alt={"Task"} />,
  shoppingCart: <img src={ShoppingCart} alt={"Shopping"} />,
  googlePartner: <img src={GooglePartner} alt={"GooglePartner"} />,
  microsoftIcon: <img src={MicrosoftIcon} alt={"Microsoft"} />,
  block1_Resizable: (w, h) => (
    <img src={Block1} alt={"Block"} height={h} width={w} />
  ),
  block2_Resizable: (w, h) => (
    <img src={Block2} alt={"Block"} height={h} width={w} />
  ),
  block3_Resizable: (w, h) => (
    <img src={Block3} alt={"Block"} height={h} width={w} />
  ),
  block1: <img src={Block1} alt={"Block"} />,
  block2: <img src={Block2} alt={"Block"} />,
  block3: <img src={Block3} alt={"Block"} />,
  meter: <img src={Meter} alt={"Block"} />,
  bookCircle: <img src={BookCircle} alt={"Block"} />,
  shoppingCartCricle: <img src={ShoppingCartCircle} alt={"Block"} />,
  blogCircle: <img src={BlogCircle} alt={"Block"} />,
  keyCircle: <img src={KeyCircle} alt={"Block"} />,
  locCircle: <img src={LocCircle} alt={"Block"} />,
  cup: <img src={Cup} alt={"Cup"} />,
  cupRed: (w, h) => (
    <img
      src={CupRed}
      alt={"Cup"}
      height={h ? h : "auto"}
      width={w ? w : "auto"}
    />
  ),
  cupYellow: (w, h) => (
    <img
      src={CupYellow}
      alt={"Cup"}
      height={h ? h : "auto"}
      width={w ? w : "auto"}
    />
  ),
  cupGreen: (w, h) => (
    <img
      src={CupGreen}
      alt={"Cup"}
      height={h ? h : "auto"}
      width={w ? w : "auto"}
    />
  ),
  pointer: <img src={Pointer} alt={"pointer"} />,
  warningCircle: <img src={WarningCircle} alt={"Warning"} />,
  onPageScore: (
    <img src={OnPageScore} alt={"OnPageScore"} width={"40%"} height={"220px"} />
  ),
  keywordScore: (
    <img src={KeywordScore} alt={"Keyword"} width={"40%"} height={"220px"} />
  ),
  linkValidityScore: (
    <img
      src={LinkValidityScore}
      width={"37%"}
      height={"200px"}
      alt={"LinkValidity"}
    />
  ),
  mobileCompatibility: (
    <img
      src={MobileCompatibility}
      width={"40%"}
      height={"200px"}
      alt={"Mobile"}
    />
  ),
  pageLoadSpeedScore: (
    <img
      src={PageLoadSpeedScore}
      width={"40%"}
      height={"230px"}
      alt={"PageLoadSpeedScore"}
    />
  ),
  technicalVisibility: (
    <img
      src={TechnicalVisibility}
      width={"50%"}
      height={"200px"}
      alt={"TechnicalVisibility"}
    />
  ),
  onPageScore_Resizable: OnPageScore,
  onPageScore_Resizable1: OnPageScore1,
  onPageScoreNew: OnPageScoreNew,
  mobileCompatibilityNew: MobileCompatibilityNew,
  keywordScore_Resizable: KeywordScore,
  keywordScore_Resizable1: KeywordScore1,
  linkValidityScore_Resizable: LinkValidityScore,
  linkValidityScore_Resizable1: LinkValidityScore1,
  websiteTechinicalScore: WebsiteTechinicalScore,
  socialMediaScore: SocialMediaScore,
  websiteTrafficAnalysis: WebsiteTrafficAnalysis,
  linkValidityScoreNew: LinkValidityScoreNew,
  mobileCompatibility_Resizable: MobileCompatibility,
  mobileCompatibility_Resizable1: MobileCompatibility1,
  pageLoadSpeedScore_Resizable: PageLoadSpeedScore,
  pageLoadSpeedScore_Resizable1: PageLoadSpeedScore1,
  technicalVisibility_Resizable: TechnicalVisibility,
  technicalVisibility_Resizable1: TechnicalVisibility1,
  ExternalAccessibility: ExternalAccessibility,
  ExternalDiscoverability: ExternalDiscoverability,
  ExternalPerformance: ExternalPerformance,
  ExternalSecurity: ExternalSecurity,
  client_2: <img src={Client_2} alt={"client_2"} width={"75%"} />,
  client_3: <img src={Client_3} alt={"Client_3"} width={"70%"} />,
  client_4: <img src={Client_4} alt={"Client_4"} width={"60%"} />,
  client_5: <img src={Client_5} alt={"Client_5"} width={"65%"} />,
  qoute_left: <img src={Qoute_left} alt={"left_qoute"} />,
  New_icon: <img src={NewIcon} alt={"New"} width={"80%"} />,
  linkIcon: <i className="fa fa-link" aria-hidden="true"></i>,
  settingIcon: <i className="fa fa-wrench" aria-hidden="true"></i>,
  leafIcon: <i className="fa fa-leaf" aria-hidden="true"></i>,
  checkedSquare: <i className="fa fa-check-square-o" aria-hidden="true"></i>,
  closeSquare: <i className="fa fa-window-close-o" aria-hidden="true"></i>,
  mobileFriendly: <img src={MobileFriendly} alt={"MobileFriendly"} />,
  mobileFriendlyNot: <img src={MobileFriendlyNot} alt={"Icon"} />,
  mobileViewPort: <img src={MobileViewPort} alt={"MobileViewPort"} />,
  mobileViewPortNot: <img src={MobileViewPortNot} alt={"MobileViewPortNot"} />,
  recommendation: recommendationIcon,
  circleCheckCustom: <img src={CircleCheckCustom} alt={"CircleCheckCustom"} />,
  activeStep: <img src={ActiveStep} alt={"ActiveStep"} />,
  envelopeMail: <i className="fa fa-envelope" aria-hidden="true"></i>,
  blueCircle: <img src={BlueCircle} alt={"Icon"} />,
  grayCircle: <img src={GrayCircle} alt={"Icon"} />,
  yellowCircle: <img src={YellowCircle} alt={"Icon"} />,
  greenCircle: <img src={GreenCircle} alt={"Icon"} />,
  errorWarning: <img src={WarningErrorAlert} alt={"Error"} />,
  errorWarningNew: (
    <img
      src={WarningErrorAlert}
      alt={"Error"}
      style={{ width: "20px", height: "20px" }}
    />
  ),
  errorCup: <img src={BlackCup} alt={"error"} />,
  googleSig: (w) => <img src={googleSig} alt={"googleSig"} width={w} />,
  bingSig: <img src={bingSig} alt={"bingSig"} />,
  fbSig: <img src={fbSig} alt={"fbSig"} />,
  isoSig: <img src={isoSig} alt={"isoSig"} />,
  upsSig: (
    <img src={upsSig} alt={"upsSig"} style={{ width: 175, height: "auto" }} />
  ),
  logo: (
    <img src={Logo} alt={"logo"} style={{ width: "100%", height: "auto" }} />
  ),
  googleSocial: <img src={GoogleIcon1} alt={"Google"} />,
  bingSocial: <img src={BingIcon1} alt={"Bing"} />,
  SEOScoreCard: <img src={SEOScoreCardIcon} alt={"SEO Score Card"} />,
  OnDemand: <img src={onDemandIcon} alt={"On demand Service"} />,
  accSettings: <img src={SettingIcon} alt={"Account Settings"} />,
  helpCenter: <img src={HelpIcon} alt={"Help Center"} />,
  holidayPackage: (
    <i
      className="fa fa-get-pocket"
      aria-hidden="true"
      alt={"Holiday Package"}
      color={"inhert"}
    ></i>
  ),
  downArrow: <img src={DownArrow} alt={"Down Arrow"} />,
  dashboard: (color) => <Dashboard1 fill={color} />,
  tasks1: (color) => <Tasks fill={color} />,
  reports: (color) => <Reports fill={color} />,
  activityReports: (color) => <ActivityReports fill={color} />,
  upArrow: <img src={upArrow} alt={"Up"} />,
  upBigArrow: <img src={upBigArrow} alt={"Up"} />,
  downBigArrow: <img src={downBig} alt={"Down"} />,
  downindicationArrow: <img src={downBig} alt={"Down"} />,
  performance: <img src={performance} alt={"performance"} />,
  square: (color) => <Square fill={color} />,
  pendingTask: <img src={pendingTask} alt={"Pending task"} />,
  xIcon: <img src={xLogo} alt={"Brand"} />,
  brand: <img src={brand} alt={"Brand"} />,
  leftArrowC: <img src={leftArrow} alt={"close"} />,
  rightArrowC: <img src={rightArrow} alt={"Open"} />,
  rightArrowT: <img src={rightArrowTask} alt={"Task pending"} />,
  lightTaskResizable: (width, height) => (
    <img src={Light} alt={"Task"} height={height} width={width} />
  ),
  menuMobile: <img src={MobileMenu} alt={"Mobile Menu"} />,
  menuMobileExternal: <img src={MobileMenu1} alt={"Mobile Menu"} />,
  brandMobile: <img src={BrandMobile} alt={"Brand Mobile"} />,
  brandMobile1: <img src={BrandMobile1} alt={"Brand Mobile"} />,
  closeMenuMobile: <img src={CloseWindow} alt={"Close"} />,
  taskCompletedIcon: <img src={TaskCompletedIcon} alt={"Task Completed"} />,
  noTasksIcon: <img src={NoTasksIcon} alt={"No Tasks"} />,
  microSoftNewIcon: <img src={MicroSoftNewIcon} alt={"Bing"} />,
  userDialog: (
    <img src={ProfileIcon} alt={"User Dialog"} width={30} height={30} />
  ),
  // dropDownArrow:<img src={DropDownArrow} alt={"Drop Down Image"}/>,
  dropDownArrow: <Cached />,
  account: <img src={account} alt={"account"} />,
  equalsArrow: <i className="fa fa-arrows-h" aria-hidden="true"></i>,
  checkCircle: <img src={CheckCircle2} alt={"Completed"} />,
  checkCircleEmpty: <img src={CheckCircleEmpty} alt={"Empty Checkbox"} />,
  mobile: <img src={Mobile} alt={"Mobile"} />,
  speedIcon: <img src={SpeedIcon} alt={"Mobile"} style={{ width: 20 }} />,
  leftIcon: <img src={backIcon} alt={"back"} />,
  closeIcon: <img src={Close} alt={"Close"} />,
  infoIcon: <img src={InfoIcon} alt={"Information"} />,
  ad: <img src={Ad} alt={"Ad copy"} />,
  orangeChecked: <img src={OrangeCircleChecked} alt={"Checked"} />,
  unCheckedNew: <img src={UnCheckedNew} alt={"Unchecked"} />,
  leftArrowB: <img src={leftArrowB} alt={"left"} />,
  accountDone: <img src={accountDone} alt={"Account Completed"} />,
  accountLive: <img src={accountLive} alt={"Account Live"} />,
  displayStand: <img src={displayStand} alt={"Display Stand"} width={"100%"} />,
  greenDot: <img src={greenDot} alt={"Default Card"} />,
  plane: <i className="fa fa-paper-plane" aria-hidden="true"></i>,
  deleteIcon: <i className="fa fa-trash" aria-hidden="true"></i>,
  cardTypeDiscover: <img src={CardTypeDiscover} alt={"CardTypeDiscover"} />,
  cardTypeJcb: <img src={CardTypeJcb} alt={"CardTypeJcb"} />,
  cardTypeDinersClub: (
    <img src={CardTypeDinersClub} alt={"CardTypeDinersClub"} />
  ),
  cardTypeMaestro: <img src={CardTypeMaestro} alt={"CardTypeMaestro"} />,
  cardTypeMasterCard: (
    <img src={CardTypeMasterCard} alt={"CardTypeMasterCard"} />
  ),
  cardTypeVisa: <img src={CardTypeVisa} alt={"CardTypeVisa"} />,
  CardTypeAmex: <img src={CardTypeAmex} alt={"CardTypeAmex"} />,
  holidayPackageIcon: (
    <img src={HolidayPackageIcon} alt={"HolidayPackageIcon"} />
  ),
  connectionVector: <img src={ConnectionIcon} alt={"ConnectionIcon"} />,
  notificationBell: (
    <img src={notificationIcon} width={20} height={25} alt={"Notification"} />
  ),
  notificationIcon1: <img src={notificationIcon1} alt={"celebrate"} />,
  notificationIcon2: <img src={notificationIcon2} alt={"Information"} />,
  notificationIcon3: <img src={notificationIcon3} alt={"Warning"} />,
  graderHomeIcon: <img src={graderHomeIcon} alt={"Google Ads Grader"} />,
  graderHomePageIcon: (
    <img src={graderHomePageIcon} alt={"Google Ads Grader"} />
  ),
  seoGraderHomeIcon: <img src={seoGraderHomeIcon} alt={"SEO Site Grader"} />,
  seoGraderHomePageIcon: (
    <img src={seoGraderHomePageIcon} alt={"SEO Site Grader"} />
  ),
  helpBg: (
    <img src={helpBg} alt={"How We Help"} width={"282px"} height="241px" />
  ),
  userCEO: <img src={userCEO} alt={"CEO"} />,
  userDoller: <img src={userDoller} alt={"Currency"} />,
  userSpeaker: <img src={userSpeaker} alt={"Speaker"} />,
  circleDot: <img src={circleDot} alt={"point"} />,
  thomesEllis: <img src={thomesEllis} alt={"owner-image"} />,
  priesterspecan: <img src={priesterspecan} alt={"priesterspecan-image"} />,
  overtenkaccountgraded: (w) => (
    <img
      src={overtenkaccountgraded}
      width={w}
      alt={"overtenkaccountgraded-image"}
    />
  ),
  aboutNetelixir: <img src={aboutNetelixir} alt={"aboutNetelixir-image"} />,
  paidsearchmanagement: (
    <img src={paidsearchmanagement} alt={"paidsearchmanagement-image"} />
  ),
  microsoftAdswh: (w) => (
    <img src={microsoftAdswh} alt={"microsoftAdswh-image"} width={w} />
  ),
  upsblackandwhitelogo: (w) => (
    <img
      src={upsblackandwhitelogo}
      alt={"upsblackandwhitelogo-image"}
      width={w}
    />
  ),
  googlepartnerwh: (w) => (
    <img src={googlepartnerwh} alt={"googlepartnerwh-image"} width={w} />
  ),
  aboutUs: (w) => <img src={aboutUs} width={w} alt={"About Us"} />,
  features: (w) => <img src={features} width={w} alt={"Features"} />,
  lxrguidegrowthmodelhp: (width) => (
    <img
      src={lxrguidegrowthmodelhp}
      alt={"lxrguidegrowthmodelhp-image"}
      width={width}
    />
  ),
  requestDemo: (w) => <img src={requestDemo} width={w} alt={"Demo Request"} />,
  requestPreview: <img src={requestPreview} alt={"Preview"} width={"100%"} />,
  orangeCheckReact: <img src={orangeReactCheck} alt={"checked"} />,
  orangeUncheck: <img src={rectangle} alt={"checked"} />,
  coffeecowlogo: <img src={coffeecowlogo} alt={"coffeecowlogo"} />,
  coffeecowowner: <img src={coffeecowowner} alt={"coffeecowowner"} />,
  cheersCollection: (w) => (
    <img src={cheersCollection} width={w} alt={"cheersCollection"} />
  ),
  intenziWebsiteLogo: (w) => (
    <img src={IntenziWebsiteLogo} width={w} alt={"Intenzi"} />
  ),
  promesaWebsiteLogo: (w) => (
    <img src={PromesaWebsiteLogo} width={w} alt={"Promesa"} />
  ),
  teacherDirectWebsiteLogo: (w) => (
    <img src={TeacherDirectWebsiteLogo} width={w} alt={"Teacher Direct"} />
  ),
  demoulinWebsiteLogo: (w) => (
    <img src={DemoulinWebsiteLogo} width={w} alt={"Demoulin"} />
  ),
  proucroWebsiteLogo: (w) => (
    <img src={ProucroWebsiteLogo} width={w} alt={"Proucro"} />
  ),
  SwanCreekCandleLogo: (w) => (
    <img src={SwanCreekCandleLogo} width={w} alt={"swancreek"} />
  ),
  planetSXSWebsiteLogo: (w) => (
    <img src={PlanetSXSWebsiteLogo} width={w} alt={"PlanetSXS"} />
  ),
  LuxuryMetalsWebsiteLogo: (w) => (
    <img src={LuxuryMetalsWebsiteLogo} width={w} alt={"LuxuryMetals"} />
  ),
  oopsyDaisyWebsiteLogo: (w) => (
    <img src={OopsyDaisyWebsiteLogo} width={w} alt={"OopsyDaisy"} />
  ),
  greenBoxArtWebsiteLogo: (w) => (
    <img src={GreenBoxArtWebsiteLogo} width={w} alt={"GreenBoxArt"} />
  ),
  ATPWebsiteLogo: (w) => (
    <img src={ATPWebsiteLogo} width={w} alt={"ATPWebsite"} />
  ),
  feedYourSoulWebsiteLogo: (w) => (
    <img src={FeedYourSoulWebsiteLogo} width={w} alt={"FeedYourSoul"} />
  ),
  culinaryClassicsLogo: (w) => (
    <img src={CulinaryClassicsLogo} width={w} alt={"CulinaryClassics"} />
  ),
  pureCoinLogo: (w) => <img src={PureCoinLogo} width={w} alt={"PureCoin"} />,
  fullForceDieselLogo: (w) => (
    <img src={FullForceDieselLogo} width={w} alt={"FullForceDiesel"} />
  ),
  fixtureDisplaysLogo: (w) => (
    <img src={FixtureDisplaysLogo} width={w} alt={"FixtureDisplays"} />
  ),
  luxuryMetals: (w) => (
    <img src={luxuryMetals} width={w} alt={"luxuryMetals"} />
  ),
  swancreek: (w) => <img src={swancreek} width={w} alt={"swancreek"} />,
  synder: (w) => <img src={synder} width={w} alt={"synder"} />,
  gooldenBarrel: (w) => (
    <img src={gooldenBarrel} width={w} alt={"gooldenBarrel"} />
  ),
  feedYourSoul: (w) => (
    <img src={feedYourSoul} width={w} alt={"feedYourSoul"} />
  ),
  netelixirPoweredby: (w) => (
    <img src={netelixirlogonewpoweredby} width={w} alt={"Netelixirpoweredby"} />
  ),
  Lxrguidewhite: <img src={Lxrguidewhite} alt={"Lxrguidewhite"} />,
  bestOffer: <img src={bestOffer} alt={"bestOffer"} />,
  microsoftLogoNew: (w) => (
    <img src={microsoftLogoNew} width={w} alt={"microsoftLogo"} />
  ),
  adwordsNewLogo: (w) => (
    <img src={adwordsNewLogo} width={w} alt={"adwordsNewLogo"} />
  ),
  featuresAndBenefitsOnline: featuresAndBenefitsOnline,
  featuresAndBenefitsAdvantage: featuresAndBenefitsAdvantage,
  featuresAndBenefitsTransparency: featuresAndBenefitsTransparency,
  featuresAndBenefitsChanges: featuresAndBenefitsChanges,
  error: <img src={errorIcon} alt={"Error 404"} />,
  microsoftPartnerNew: (w) => (
    <img
      src={microsoftPartnerNew}
      alt={"microsoftPartnerNew-image"}
      width={w}
    />
  ),
  upsReadyPartnerLogoNew: (w) => (
    <img
      src={upsReadyPartnerLogoNew}
      alt={"upsReadyPartnerLogoNew-image"}
      width={w}
    />
  ),
  microsoftNewlogoft: (w) => (
    <img src={microsoftAdsPartnerlogonewftr} width={w} alt={"bingSig"} />
  ),
  upsNewLogoFooter: (w) => (
    <img src={upsNewlogofooter} alt={"upsNewlogo"} width={w} />
  ),
  upsWithBlackBorder: (w) => (
    <img src={upsWithBlackBorder} alt={"upsNewlogo"} width={w} />
  ),
  guidedwalkthrough: <img src={guidedwalkthrough} />,
  shopifyEcommerce: (w) => <img src={shopifyLXR} width={w} alt={"Shopify"} />,
  wooEcommerce: (w) => (
    <img src={wooCommerceLXR} width={w} alt={"WooEcommerce"} />
  ),
  bigEcommerce: (w) => (
    <img src={bigCommerceLXR} width={w} alt={"BigEcommerce"} />
  ),
  leadGenSec1: <img src={speaker} alt={"checked"} />,
  leadGenSec2: <img src={setting1} alt={"checked"} />,
  leadGenSec3: <img src={mobIcon} alt={"checked"} />,
  // leadGenSec3:<img src={mobIcon} alt={"checked"} />,
  leadGenSec4: <img src={insights1} alt={"insights"} />,
  saveCostForecast: <img src={saveCostForecast} />,
  webDev: <img src={webDev_1} width={18} alt={"Web Development"} />,
  HolidayBoost: <img src={holidayBoost} width={18} alt={"Holiday Boost"} />,
  tour: <img src={tour} alt={"product tour"} />,
  subscription: <img src={subscription} alt={"subscription"} />,
  AutoApply: <img src={AutoApply} alt={"AutoApply"} />,
  AutoApplyWhite: <img src={AutoApplyWhite} alt={"AutoApply"} />,
  AutoApplyUpdated: <img src={AutoApplyUpdated} alt={"AutoApply"} />,
  LineSeperater: <img src={LineSeperater} alt={"LineSeperater"} />,
  NetElixirLogo: <img src={NetElixirLogo} alt="NetElixirLogo" width={140} />,
  HorizonatDividers: <img src={HorizonatDivider} alt={"HDivider"} />,
  CalenderIcon: (
    <img src={Calendar} alt={"Calender"} style={{ cursor: "pointer" }} />
  ),
  RectangleIcon: <img src={Rectangle} alt={"Rectangle"} />,
  GAMaleIcon: <img src={GAmalePersona} alt={GAmalePersona} width={125} />,
  GAFemaleIcon: <img src={GAfemalePersona} alt={GAfemalePersona} width={125} />,
  GoogleAnalyticsDark: (
    <img src={GoogleAnalyticsDark} alt={GoogleAnalyticsDark} />
  ),
  GoogleAnalyticsLight: (
    <img src={GoogleAnalyticsLight} alt={GoogleAnalyticsLight} />
  ),
  upwardArrow: <img src={upwardArrow} alt={upwardArrow} />,
  downwardArrow: <img src={downwardArrow} alt={downwardArrow} />,

  check: <img src={check} alt={check} width={20} />,
  cross: <img src={cross} alt={cross} width={20} />,

  pvOneDotofthree: <img src={pvOneDotofthree} alt={pvOneDotofthree} />,
  pvTwoDotofthree: <img src={pvTwoDotofthree} alt={pvTwoDotofthree} />,
  pvThreeDotofthree: <img src={pvThreeDotofthree} alt={pvThreeDotofthree} />,
  minmax: <img src={minmax} alt={minmax} style={{ width: "100%" }} />,
  googleanalyticsbanner: (
    <img src={googleanalyticsbanner} alt={googleanalyticsbanner} />
  ),
  whs_logo: <img src={whs_logo} alt={"websiteHealthScoreCardLogo"} />,
  whs_logo_2: <img src={whs_logo_2} alt={"websiteHealthScoreCardLogo"} />,
  // discoverabilityscorelogo: (
  //   <img
  //     src={discoverabilityscorelogo}
  //     alt={"discoverabilityscorelogo"}
  //     width={50}
  //   />
  // ),
  // performancescorelogo: (
  //   <img src={performancescorelogo} alt={"performancescorelogo"} width={50} />
  // ),
  // accessibilityscorelogo: (
  //   <img
  //     src={accessibilityscorelogo}
  //     alt={"accessibilityscorelogo"}
  //     width={50}
  //   />
  // ),
  // securityscorelogo: (
  //   <img src={securityscorelogo} alt={"securityscorelogo"} width={50} />
  // ),
  // scoretrophylogo: (
  //   <img src={scoretrophylogo} alt={"scoretrophylogo"} width={50} />
  // ),
  // whs_vertical_line: <img src={whs_vertical_line} alt={"whs_vertical_line"} style={{paddingTop:"30px"}} />,
  WHSExternalLink: <img src={WHSExternalLink} alt={"WHS External link"} />,
  WHSRefresh: <img src={WHSRefresh} alt={"WHS Refresh"} />,
  WHSBulb: <img src={WHSBulb} alt={"WHS Bulb"} style={{ width: "65px" }} />,
  WHSErrorBulb: (
    <img src={WHSErrorBulb} alt={"WHS Error Bulb"} style={{ width: "70px" }} />
  ),
  WHSGoodOverallScore: (
    <img
      src={WHSGoodOverallScore}
      alt={"WHS Good Overall Score"}
      style={{ width: "200px" }}
    />
  ),
  WHSGoodOverallScore1: (
    <img
      src={WHSGoodOverallScore}
      alt={"WHS Good Overall Score"}
      style={{ width: "100%" }}
    />
  ),
  WHSConsultation: <img src={WHSConsultation} alt={"WHS Consultation"} />,
  WHSDiscoverabilityInfo: (
    <img src={WHSDiscoverabilityInfo} alt={"WHS Discoverability Info"} />
  ),
  WHSPerformanceInfo: (
    <img src={WHSPerformanceInfo} alt={"WHS Performance Info"} />
  ),
  WHSInfo: (
    <img src={WHSInfo} alt={"WHS Info icon"} style={{ cursor: "pointer" }} />
  ),
  WHSInstagram: <img src={WHSInstagram} alt={"WHS Instagram icon"} />,
  WHSFacebook: <img src={WHSFacebook} alt={"WHS Facebook icon"} />,
  WHSTwitter: <img src={WHSTwitter} alt={"WHS Twitter icon"} />,
  WHSLinkedIn: <img src={WHSLinkedIn} alt={"WHS LinkedIn icon"} />,
  WHSYoutube: <img src={WHSYoutube} alt={"WHS Youtube icon"} />,
  WHSHorizontalLine: (
    <img
      src={WHSHorizontalLine}
      alt={"WHS  Horizontal Line"}
      style={{ width: "100%" }}
    />
  ),
  WHSChecked: <img src={WHSChecked} alt={"WHS Checked Icon"} />,
  WHSUnchecked: <img src={WHSUnchecked} alt={"WHS Unchecked Icon"} />,
  WHSAccessibilityInfo: (
    <img src={WHSAccessibilityInfo} alt={"WHS Accessibility Info Icon"} />
  ),
  WHSSecurityInfo: <img src={WHSSecurityInfo} alt={"WHS Security Info Icon"} />,
  WHSVerticalLine: <img src={WHSVerticalLine} alt={"WHS Vertical line"} />,
  WHSPoorOverallScore: (
    <img
      src={WHSPoorOverallScore}
      alt={"WHS Poor Overall Score"}
      style={{ width: "200px" }}
    />
  ),
  WHSPoorOverallScore1: (
    <img
      src={WHSPoorOverallScore}
      alt={"WHS Poor Overall Score"}
      style={{ width: "100%" }}
    />
  ),
  WHSPoint1: <img src={WHSPoint1} alt={"WHS Point 1"} />,
  WHSPoint2: <img src={WHSPoint2} alt={"WHS Point 2"} />,
  WHSPoint3: <img src={WHSPoint3} alt={"WHS Point 3"} />,
  WHSDescription: <img src={WHSDescription} alt={"WHS Description icon"} />,
  ExternalWHSIcon: (
    <img src={ExternalWHSIcon} alt={"WHS Homepage icon"} width={"120px"} />
  ),
  WHSIconNew: (
    <img
      src={ExternalWHSIcon}
      alt={"WHS Homepage icon"}
      width={"70px"}
      height={"70px"}
    />
  ),

  minmax: <img src={minmax} alt={minmax} style={{ width: "100%" }} />,
  googleanalyticsbanner: (
    <img src={googleanalyticsbanner} alt={googleanalyticsbanner} />
  ),
  rsaErrorStatusIcon: <img src={RsaErrorIcon} alt={"Failed"} />,
  rsaPendingStatusIcon: <img src={RsaPendingIcon} alt={"Pending"} />,
  rsaPublishedStatusIcon: <img src={RsaPublishedIcon} alt={"Published"} />,
  rsaReadyToPublishStatusIcon: (
    <img src={RsaReadyToPublish} alt={"Ready to publish"} />
  ),
  rsaToolTipIcon: (
    <img src={RsaToolTipIcon} alt={"Tooltip"} style={{ cursor: "pointer" }} />
  ),
  New_Sticker: (
    <img src={New_Sticker} alt={"New Sticker"} style={{ width: "40px" }} />
  ),

  GoogleAds: <img src={GoogleAds} alt={"Google Ads"} />,
  BingAds: <img src={BingAds} alt={"Bing Ads"} />,
  GMC: <img src={GMC} alt={"Google Merchant Center"} />,
  ShoppingFeed: <img src={ShoppingFeed} alt={"Shopping Feed"} />,
  SegregationLine: <img src={SegregationLine} alt={"Segregation line"} />,
  BigCommerce: (
    <img src={BigCommerce} alt={"Big Commerce"} style={{ width: "85%" }} />
  ),
  WooCommerce: (
    <img src={WooCommerce} alt={"Woo Commerce"} style={{ width: "85%" }} />
  ),
  Shopify: <img src={Shopify} alt={"Shopify"} style={{ width: "85%" }} />,
  PromocodeClear: (
    <img src={PromocodeClear} alt="clear" style={{ width: "20px" }} />
  ),

  JumpstartLogin: (
    <img src={JumpstartLogin} alt="Jumpstart Login" style={{ width: "100%" }} />
  ),
  LxrguideLogo: (
    <img
      src={LxrguideLogo}
      alt="Jumpstart LXRGuide logo"
      style={{ width: "100%" }}
    />
  ),
  SignIn: <img src={SignIn} alt="Jumpstart SignIn icon" />,
  AccountsMenu: <img src={AccountsMenu} alt="Accounts Menu icon" />,
  UsersMenu: <img src={UsersMenu} alt="Users Menu icon" />,
  ExcelUpload: <img src={ExcelUpload} alt="Excel upload icon" />,
  ExcelDownload: <img src={ExcelDownload} alt="Excel download icon" />,
  FrameworkCompleted: (
    <img src={FrameworkCompleted} alt="Framework Completed" />
  ),
  HorizontalDashedLine: (
    <img src={HorizontalDashedLine} alt="Horizontal Dashed Line" />
  ),
  JsOpenIcon: <img src={JsOpenIcon} alt="Open" />,
  JsCloseIcon: (
    <img
      src={JsCloseIcon}
      alt="Close"
      style={{ position: "relative", zIndex: 1 }}
    />
  ),
  JsCampaignIcon: <img src={JsCampaignIcon} alt="Campaign" />,
  JsAdgroupIcon: <img src={JsAdgroupIcon} alt="AdGroup" />,
  JsBusinessIcon: <img src={JsBusinessIcon} alt="Business" />,
  CampaignStructureCompleted: (
    <img src={CampaignStructureCompleted} alt="Campaign Structure Completed" />
  ),
  PasswordResetEmail: <img src={PasswordResetEmail} alt="Password Reset" />,
  PasswordChanged: <img src={PasswordChanged} alt="Password Changed" />,
  JumpstartLogo: <img src={JumpstartLogo} alt="Jumpstart Logo" />,
  GoogleSearchConsole: (
    <img src={GoogleSearchConsole} alt={"Google Search Console"} />
  ),
};

export default icons;
