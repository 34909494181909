import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  NewOrangeSaveContainedCardBtn,
} from "../../../components/buttons";

export default function ResetStatusDialog(props) {
  const {
    openDialog,
    handleCloseDialog,
    sectionHeading,
    status,
    options,
    setStatusColor,
    FormatAccountStatus,
    handleRadioChange,
    handleStatusChange,
    loading
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="status-dialog-title"
      aria-describedby="status-dialog-description"
      fullScreen={fullScreen}
    >
      <DialogContent sx={{ width: "500px" }}>
        <DialogContentText id="status-dialog-description">
          <Grid container direction={"column"} rowGap={2}>
            <Grid
              item
              container
              direction="row"
              justifyContent={"space-between"}
              alignContent={"center"}
            >
              <Grid item xs={6}>
                <Typography color={"#838383"} variant="subtitle">
                  Current Stage:
                </Typography>
                <Typography variant="subtitle2" color={"#f58120"}>
                  {sectionHeading}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <span style={{ float: "right" }}>
                  <Typography color={"#838383"} variant="subtitle2">
                    Current Status:
                  </Typography>
                  <Chip
                    label={FormatAccountStatus(status)}
                    className={setStatusColor(status)}
                  />
                </span>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              container
              direction="row"
              justifyContent={"space-between"}
            >
              <Grid item xs={4}>
                <Typography color={"#838383"}>
                  Change status to:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="status-label"
                    defaultValue="CAMPAIGN_FRAMEWORK_IN_PROGRESS"
                    name="radio-buttons-group"
                    onChange={(e) => handleRadioChange(e)}
                  >
                    {options.map((option, index) => (
                      <FormControlLabel
                        value={option.value}
                        control={<Radio size="small" color="secondary" />}
                        label={option.label}
                        key={index}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item></Grid>
            <Grid
              item
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              rowGap={1}
            >
              <Grid item>
                <Typography color={"#838383"}>
                  Are you sure you want to change status?
                </Typography>
              </Grid>
              <Grid item>
                <NewOrangeSaveContainedCardBtn onClick={()=>handleStatusChange()} disabled={loading}>
                  {loading ? <CircularProgress sx={{ color: "#fff" }} size="1.5rem" /> : "Confirm"}
                </NewOrangeSaveContainedCardBtn>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
