import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchJsAccounts,
  fetchJsAccountById,
} from "../../../actions/jumpstartRevamped";
import "../jumpstart.css";
import JsAccounts from "./jsAccounts";
import { useParams } from "react-router-dom";
import JumpstartMain from "../jumpstartMain";
import JsAnalyticsHeader from "../jsAnalyticsHeader";
import {  AccountStage} from "../commonComponents/jumpstartEnum";

function JsAccountManagement(props) {
  const { fetchJsAccounts, fetchJsAccountsReducer, fetchJsAccountById, match } =
    props;
  window.scrollTo(0, 0);
  const [loading, setLoading] = useState(false);
  const [jwtToken, setJwtToken] = useState(sessionStorage.getItem("jwtToken"));
  const [jsAccounts, setJsAccounts] = useState();

  const [campaignFrameworkAccounts, setCampaignFrameworkAccounts] = useState(
    []
  );
  const [campaignStructureAccounts, setCampaignStructureAccounts] = useState(
    []
  );

  const { id } = useParams();

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  useEffect(() => {
    async function fetchJsData() {
      setLoading(true);
      await fetchJsAccounts(jwtToken);
      setLoading(false);
    }
    fetchJsData();
  }, []);

  useEffect(() => {
    if (fetchJsAccountsReducer.message === "JS_ACCOUNTS_RECEIVED") {
      const { message, status, ...jsData } = fetchJsAccountsReducer;
      const jsDataArray = Object.values(jsData);
      setJsAccounts(jsDataArray);
  
      // Filter and overwrite directly
      const frameworkAccounts = jsDataArray.filter(
        (account) =>
          account.status.includes("CAMPAIGN_FRAMEWORK") ||
          account.status.includes("CREATED")
      );
      const structureAccounts = jsDataArray.filter((account) =>
        account.status.includes("CAMPAIGN_STRUCTURE")
      );
  
      setCampaignFrameworkAccounts(frameworkAccounts);
      setCampaignStructureAccounts(structureAccounts);
    }
  }, [fetchJsAccountsReducer]);
  

  return (
    <JumpstartMain>
      <div className="pageContent">
        <JsAnalyticsHeader
          campaignFrameworkAccountsLength={campaignFrameworkAccounts.length}
          campaignStructureAccountsLength={campaignStructureAccounts.length}
          currentTabIndex={currentTabIndex}
          handleTabChange={handleTabChange}
        />
        <JsAccounts
          jsAccounts={
            currentTabIndex == 0
              ? campaignFrameworkAccounts
              : currentTabIndex == 1
              ? campaignStructureAccounts
              : []
          }
          accountStage={
            currentTabIndex == 0 ?
            AccountStage.CAMPAIGN_FRAMEWORK :
            AccountStage.CAMPAIGN_STRUCTURE
          }
          loading={loading}
        />
      </div>
    </JumpstartMain>
  );
}

const mapStateToProps = (state) => ({
  fetchJsAccountsReducer: state.fetchJsAccountsReducer,
});
const mapDispatchToProps = (dispatch) => ({
  fetchJsAccounts: (jwtToken) => dispatch(fetchJsAccounts(jwtToken)),
  fetchJsAccountById: (jwtToken, id) =>
    dispatch(fetchJsAccountById(jwtToken, id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JsAccountManagement);
