import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { AppBar, Button, IconButton, Toolbar } from "@mui/material/index";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import taskDataAppBarStyles from "../assets/jss/task/taskDataAppBarStyles";
import {
  PublishOrangeContainedBtn,
  RoyalBlueUnderlinedButton,
} from "../components/buttons";
import icons from "../components/icons";
import Savings from "../components/task/savings";
import { color } from "d3";
function TaskDataAppBar(props) {
  const {
    impact,
    KPIType,
    currency,
    timeEstimate,
    selectedImpact,
    classes,
    taskTypeId,
    width,
    isTaskExecutionLoading,
    actionStatus,
    actionStatusDate,
    saveStatus,
  } = props;
  console.log("taskTypeId=>", taskTypeId);
  console.log("impact=>", impact);
  console.log("KPIType=>", KPIType);
  console.log("currency=>", currency);
  console.log("timeEstimate=>", timeEstimate);
  console.log("selectedImpact=>", selectedImpact);
  console.log("classes=>", classes);
  console.log("isTaskExecutionLoading=>", isTaskExecutionLoading);

  console.log("actionStatus---->", actionStatus);
  console.log("actionStatusDate ---->", actionStatusDate);

  var dateStringMili = new Date(actionStatusDate);
  console.log("dateStringMili-->", dateStringMili);

  var dateStringFormat = dateStringMili.toLocaleDateString("es-CL");
  // var dateStringFormat = dateStringMili.toLocaleDateString();
  var dateStringFormatTime = dateStringMili.toLocaleTimeString("es-CL");

  console.log("dateStringFormat-->", dateStringFormat);
  console.log("dateStringFormatTime-->", dateStringFormatTime);

  dateStringFormat = dateStringFormat.replaceAll("-", "/");
  // dateStringFormatTime=dateStringFormatTime.replace("-", "/");

  // const stringofAutotaskIds= ['2','5','10','11','14','22','41','42']
  console.log("props.summary.timeEstimate TaskDataAppBar:", timeEstimate);
  const DateAndTimeMsg = {
    fontSize: "14px",
    color: "#A2B6C3",
    // fontWeight: 400 !Important,
  };
  return (
    <AppBar
      position="fixed"
      className={classNames(props.classes.appBar, {
        [props.classes.appBarShift]: props.open,
        [props.classes.newClass]: !props.open,
      })}
    >
      <Toolbar disableGutters={!props.open} className={props.classes.toolbar}>
        {!props.open && !(width == "sm" || width == "xs") ? (
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            // onClick={this.handleDrawerOpen}
            className={classNames(!props.open && props.classes.menuButton)}
            size="large"
          >
            {/*<MenuIcon />*/}
            &nbsp;
          </IconButton>
        ) : null}

        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className={props.classes.space}
        >
          <Grid item sm={12} xs={12} md={12} className={classes.appBarGrid}>
            <Button
              onClick={props.onClick}
              className={classes.btn}
              style={{ textTransform: "none" }}
            >
              {icons.leftIcon}&nbsp;
              <b>Back</b>
            </Button>
          </Grid>
          <Grid item sm={12} xs={12} md={6}>
            <Typography
              variant={width == "sm" || width == "xs" ? "body1" : "h4"}
              color="textPrimary"
              noWrap={width == "sm" || width == "xs" ? false : true}
            >
              <b>{props.title}</b>
            </Typography>
            {/* <Typography variant="h6" color="textPrimary" noWrap gutterBottom>
                            {props.title}
                        </Typography>*/}
            <Typography variant={"caption"} style={{ display: "flex" }}>
              {taskTypeId != 51 && (
                <Savings
                  {...impact}
                  KPIType={KPIType}
                  currency={currency}
                  timeSavedEstimate={timeEstimate}
                />
              )}
            </Typography>
            {/* //------------------------------------------ */}
            {actionStatus === 5 ? (
              <Typography style={DateAndTimeMsg}>
                <b>
                  This task was published automatically on Date:{" "}
                  {/* {actionStatusDate} on your Google Ads */}
                  {dateStringFormat} Time: {dateStringFormatTime} on your Google
                  Ads Account.
                </b>
              </Typography>
            ) : null}
            {/* ================================================== */}
          </Grid>
          <Grid item md={6}>
            {/* { stringofAutotaskIds.includes(taskTypeId.toString()) && actionStatus===5  ? ( */}
            {actionStatus === 5 ? (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div className={classes.wrapper}>
                  <RoyalBlueUnderlinedButton
                    id={"publish-btn-" + taskTypeId}
                    variant={"contained"}
                    size={"large"}
                    disabled={
                      props.selectedIds.length == 0 || isTaskExecutionLoading
                    }
                    // onClick={props.onClick1}
                    style={{ color: "white", backgroundColor: "#A2B6C3" }}
                  >
                    PUBLISHED AUTOMATICALLY
                  </RoyalBlueUnderlinedButton>
                </div>
              </div>
            ) : taskTypeId == 51 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h4"
                  style={{ color: "#949EAB", paddingRight: "30px" }}
                >
                  {props.saveStatus}
                </Typography>
                <div className={classes.wrapper}>
                  <PublishOrangeContainedBtn
                    id={"publish-btn-" + taskTypeId}
                    variant={"contained"}
                    size={"large"}
                    disabled={
                      !props.selectedAdCopies ||
                      props.selectedAdCopies.length == 0 ||
                      isTaskExecutionLoading
                    }
                    onClick={props.onClick2}
                  >
                    {isTaskExecutionLoading ? (
                      <>
                        <CircularProgress
                          // className={classes.buttonProgress}
                          color="secondary"
                          size={24}
                        />
                        &nbsp;Publishing in progress
                      </>
                    ) : (
                      "PUBLISH"
                    )}
                  </PublishOrangeContainedBtn>
                </div>
              </div>
            ) : taskTypeId != 54 && taskTypeId != 49 ? (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div className={classes.wrapper}>
                  <PublishOrangeContainedBtn
                    id={"publish-btn-" + taskTypeId}
                    variant={"contained"}
                    size={"large"}
                    disabled={
                      props.selectedIds.length == 0 || isTaskExecutionLoading
                    }
                    onClick={props.onClick1}
                  >
                    {isTaskExecutionLoading ? (
                      <>
                        <CircularProgress
                          // className={classes.buttonProgress}
                          color="secondary"
                          size={24}
                        />
                        &nbsp;Publishing in progress
                      </>
                    ) : (
                      "PUBLISH"
                    )}
                  </PublishOrangeContainedBtn>
                </div>
              </div>
            ) : null}

            <Typography variant={"caption"} align={"right"}>
              <Savings {...selectedImpact} />
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

TaskDataAppBar.propTypes = {
  classes: PropTypes.any,
  open: PropTypes.any,
  onClick: PropTypes.func,
  title: PropTypes.any,
  selectedIds: PropTypes.any,
  onClick1: PropTypes.func,
  onClick2: PropTypes.func,
};

export default withStyles(taskDataAppBarStyles)(TaskDataAppBar);
