export var UPDATE_TASK_DATA_STATUS = "UPDATE_TASK_DATA_STATUS";
export var UPDATE_TASK_DATA_STATUS_TASK_TYPE_51 =
  "UPDATE_TASK_DATA_STATUS_TASK_TYPE_51";
export const PERF_DATA_REQUESTED = "PERF_DATA_REQUESTED";
export const PERF_KPI_GRAPH_DATA_REQUESTED = "PERF_KPI_GRAPH_DATA_REQUESTED";
export const PERF_DATA_RECEIVED = "PERF_DATA_RECEIVED";
export const PERF_DATA_REQUEST_FAILED = "PERF_DATA_REQUEST_FAILED";
export const SEEN_TASK = "SEEN_TASK";
export const FEEDBACK_SUCCESS = "FEEDBACK_SUCCESS";
export var UPDATE_CURRENCY = "UPDATE_CURRENCY";
export var UPDATE_KPI_TYPE_UI = "UPDATE_KPI_TYPE_UI";
export var UPDATE_REPORT_KPI_TYPE_UI = "UPDATE_REPORT_KPI_TYPE_UI";

export const GENERATE_RSA_ADCOPY_REQUESTED = "GENERATE_RSA_ADCOPY_REQUESTED";
export const GENERATE_RSA_ADCOPY_RECEIVED = "GENERATE_RSA_ADCOPY_RECEIVED";
export const GENERATE_RSA_ADCOPY_FAILED = "GENERATE_RSA_ADCOPY_FAILED";

export const SAVE_RSA_DATA_REQUESTED = "SAVE_RSA_DATA_REQUESTED";
export const SAVE_RSA_DATA_RECEIVED = "SAVE_RSA_DATA_RECEIVED";
export const SAVE_RSA_DATA_FAILED = "SAVE_RSA_DATA_FAILED";

export const FETCH_RSA_DATA_REQUESTED = "FETCH_RSA_DATA_REQUESTED";
export const FETCH_RSA_DATA_RECEIVED = "FETCH_RSA_DATA_RECEIVED";
export const FETCH_RSA_DATA_FAILED = "FETCH_RSA_DATA_FAILED";

export const SELECT_DATE_RANGE = "SELECT_DATE_RANGE";
export const RESET_DATE_RANGE = "RESET_DATE_RANGE";
export const SELECT_CUSTOM_DATE_RANGE = "SELECT_CUSTOM_DATE_RANGE";
export const SELECT_GRAPH_DATE_RANGE = "SELECT_GRAPH_DATE_RANGE";
export const RESET_GRAPH_DATE_RANGE = "RESET_GRAPH_DATE_RANGE";
export const SELECT_GRAPH_CUSTOM_DATE_RANGE = "SELECT_GRAPH_CUSTOM_DATE_RANGE";
export const SELECT_REPORT_GRAPH_DATE_RANGE = "SELECT_REPORT_GRAPH_DATE_RANGE";
export const RESET_REPORT_GRAPH_DATE_RANGE = "RESET_REPORT_GRAPH_DATE_RANGE";
export const SELECT_REPORT_GRAPH_CUSTOM_DATE_RANGE =
  "SELECT_REPORT_GRAPH_CUSTOM_DATE_RANGE";
export const SELECT_REPORT_PAGE_DATE_RANGE = "SELECT_REPORT_PAGE_DATE_RANGE";
export const RESET_REPORT_PAGE_DATE_RANGE = "RESET_REPORT_PAGE_DATE_RANGE";
export const SELECT_REPORT_PAGE_CUSTOM_DATE_RANGE =
  "SELECT_REPORT_PAGE_CUSTOM_DATE_RANGE";
export const TOGGLE_REPORTS_METRIC = "TOGGLE_REPORTS_METRIC";

export const GET_TASK_DATA = "GET_TASK_DATA";
export const FETCH_TASK_DATA = "FETCH_TASK_DATA";
export const TASK_DATA_FETCH_FAILED = "TASK_DATA_FETCH_FAILED";
export const TASK_DATA_RECEIVED = "TASK_DATA_RECEIVED";
export const UPDATE_FEEDBACK_STATUS = "UPDATE_FEEDBACK_STATUS";
export const ON_DEMAND_CART_SAVED = "ON_DEMAND_CART_SAVED";
export const ON_DEMAND_PAYMENT_REQUESTED = "ON_DEMAND_PAYMENT_REQUESTED";
export const ON_DEMAND_PAYMENT_FAILED = "ON_DEMAND_PAYMENT_REQUESTED_FAILED";
export const ON_DEMAND_PAYMENT_RECEIVED = "ON_DEMAND_PAYMENT_RECEIVED";
export const ON_DEMAND_PAYMENT_STATUS = "ON_DEMAND_PAYMENT_STATUS";
export const UPDATE_MINI_SERVICE_ITEM_COUNT = "UPDATE_MINI_SERVICE_ITEM_COUNT";
export const RESET_CART_COUNT = "RESET_CART_COUNT";
export const RESET_INPUT_FORMS = "RESET_INPUT_FORMS";
export const UPDATE_MINI_SERVICE_INPUTS = "UPDATE_MINI_SERVICE_INPUTS";
export const CARD_NO_REQUESTED = "CARD_NO_REQUESTED";
export const CARD_NO_RECEIVED = "CARD_NO_RECEIVED";
export const ACCOUNT_STRUCTURE_REQUESTED = "ACCOUNT_STRUCTURE_REQUESTED";
export const ACCOUNT_STRUCTURE_RECEIVED = "ACCOUNT_STRUCTURE_RECEIVED";
export const ACCOUNT_STRUCTURE_REQUESTED_FAILED =
  "ACCOUNT_STRUCTURE_REQUESTED_FAILED";
export const ADMIN_USER_TABLE_DATA_REQUESTED =
  "ADMIN_USER_TABLE_DATA_REQUESTED";
export const ADMIN_USER_TABLE_DATA_RECEIVED = "ADMIN_USER_TABLE_DATA_RECEIVED";
export const ADMIN_USER_TABLE_DATA_REQUESTED_FAILED =
  "ADMIN_USER_TABLE_DATA_REQUESTED_FAILED";
export const ADMIN_PROV_USER_TABLE_DATA_REQUESTED =
  "ADMIN_PROV_USER_TABLE_DATA_REQUESTED";
export const ADMIN_PROV_USER_TABLE_DATA_RECEIVED =
  "ADMIN_PROV_USER_TABLE_DATA_RECEIVED";
export const ADMIN_PROV_USER_TABLE_DATA_FAILED =
  "ADMIN_PROV_USER_TABLE_DATA_FAILED";
export const LOAD_ADMIN_USER_TABLE_DATA_FROM_REDUCER =
  "LOAD_ADMIN_USER_TABLE_DATA_FROM_REDUCER";
export const SET_CURRENT_AND_SORTING_STATE = "SET_CURRENT_AND_SORTING_STATE";
export const UPDATE_Premier_PACK_MONTHLY_TOGGLE =
  "UPDATE_Premier_PACK_MONTHLY_TOGGLE";
export const UPDATE_SEO_PACK_TRIMONTHLY_TOGGLE =
  "UPDATE_SEO_PACK_TRIMONTHLY_TOGGLE";


export const UPDATE_USER_NOTE_REQUESTED = "UPDATE_USER_NOTE_REQUESTED";
export const UPDATE_USER_NOTE_RECEIVED = "UPDATE_USER_NOTE_RECEIVED";
export const UPDATE_USER_INFORMATION = "UPDATE_USER_INFORMATION";
export const ADD_USER_INFORMATION = "ADD_USER_INFORMATION";
export const UPDATE_USER_NOTE_REQUESTED_FAILED =
  "UPDATE_USER_NOTE_REQUESTED_FAILED";

export const GET_USER_NOTE_REQUESTED = "GET_USER_NOTE_REQUESTED";
export const RESET_TABLE_ROWS = "RESET_TABLE_ROWS";
export const GET_USER_NOTE_RECEIVED = "GET_USER_NOTE_RECEIVED";
export const GET_USER_NOTE_REQUESTED_FAILED = "GET_USER_NOTE_REQUESTED_FAILED";
export const DELETE_USER_REQUEST_FAILED = "DELETE_USER_REQUEST_FAILED";
export const END_USER_JUMPSTART_REQUEST_FAILED =
  "END_USER_JUMPSTART_REQUEST_FAILED";
export const END_DISABLE_USER_REQUEST_FAILED =
  "END_DISABLE_USER_REQUEST_FAILED";
export const HANDLE_DELETE_USER_ALERT = "HANDLE_DELETE_USER_ALERT";
export const USER_INFO_REQUESTED = "USER_INFO_REQUESTED";
export const GET_USER_REQUEST_FAILED = "GET_USER_REQUEST_FAILED";

export const USER_ACCOUNT_REQUESTED = "USER_ACCOUNT_REQUESTED";
export const USER_ACCOUNT_RECEIVED = "USER_ACCOUNT_RECEIVED";
export const USER_ACCOUNT_FAILED = "USER_ACCOUNT_FAILED";
export const ADMIN_DATE_TARGET = "ADMIN_DATE_TARGET";
export const KEYWORD_HIERARCHY_STATUS_REQUESTED =
  "KEYWORD_HIERARCHY_STATUS_REQUESTED";
export const KEYWORD_HIERARCHY_STATUS_FAILED =
  "KEYWORD_HIERARCHY_STATUS_FAILED";
export const KEYWORD_HIERARCHY_STATUS_RECEIVED =
  "KEYWORD_HIERARCHY_STATUS_RECEIVED";
export const TOGGLE_IS_ACCESS = "TOGGLE_IS_ACCESS";
export const UPDATE_KEYWORD_HIERARCHY = "UPDATE_KEYWORD_HIERARCHY";

export const GOOGLE_ADS_PERFORMANCE_REQUESTED =
  "GOOGLE_ADS_PERFORMANCE_REQUESTED";
export const GOOGLE_ADS_PERFORMANCE_RECEIVED =
  "GOOGLE_ADS_PERFORMANCE_RECEIVED";
export const GOOGLE_ADS_PERFORMANCE_FAILED = "GOOGLE_ADS_PERFORMANCE_FAILED";
export const UPDATE_SELECTED_DATE = "UPDATE_SELECTED_DATE";
export const GRADER_INTEGRATION_STATUS_REQUESTED =
  "GRADER_INTEGRATION_STATUS_REQUESTED";
export const GRADER_INTEGRATION_STATUS_RECEIVED =
  "GRADER_INTEGRATION_STATUS_RECEIVED";
export const GRADER_INTEGRATION_STATUS_FAILED =
  "GRADER_INTEGRATION_STATUS_FAILED";

export const SITE_GRADER_RESULT_REQUESTED = "SITE_GRADER_RESULT_REQUESTED";
export const SITE_GRADER_RESULT_RECEIVED = "SITE_GRADER_RESULT_RECEIVED";
export const SITE_GRADER_RESULT_FAILED = "SITE_GRADER_RESULT_FAILED";
export const SITE_GRADER_URL_INFO_REQUESTED = "SITE_GRADER_URL_INFO_REQUESTED";
export const SITE_GRADER_URL_INFO_RECEIVED = "SITE_GRADER_URL_INFO_RECEIVED";
export const SITE_GRADER_URL_INFO_RECEIVED1 = "SITE_GRADER_URL_INFO_RECEIVED1";
export const SITE_GRADER_URL_INFO_FAILED = "SITE_GRADER_URL_INFO_FAILED";
export const SET_SITE_GRADER_ERROR = "SET_SITE_GRADER_ERROR";
export const SITE_GRADER_RESET = "SITE_GRADER_RESET";
export const SITE_GRADER_SEO_REPORT_STATUS_REQUESTED =
  "SITE_GRADER_SEO_REPORT_REQUESTED";
export const SITE_GRADER_SEO_REPORT_STATUS_RECEIVED =
  "SITE_GRADER_SEO_REPORT_STATUS_RECEIVED";
export const SITE_GRADER_SEO_REPORT_STATUS_FAILED =
  "SITE_GRADER_SEO_REPORT_STATUS_FAILED";

export const SAVE_SEO_USER_REQUESTED = "SAVE_SEO_USER_REQUESTED";
export const SAVE_SEO_USER_RECEIVED = "SAVE_SEO_USER_RECEIVED";
export const SAVE_SEO_USER_FAILED = "SAVE_SEO_USER_FAILED";
export const EMAIL_VALID_REQUESTED = "EMAIL_VALID_REQUESTED";
export const EMAIL_VALID_RECEIVED = "EMAIL_VALID_RECEIVED";
export const EMAIL_VALID__FAILED = "EMAIL_VALID__FAILED";

export const SEO_USER_SERVICES_DETAILS_REQUESTED =
  "SEO_USER_SERVICES_DETAILS_REQUESTED";
export const SEO_USER_SERVICES_DETAILS_RECEIVED =
  "SEO_USER_SERVICES_DETAILS_RECEIVED";
export const SEO_USER_SERVICES_DETAILS_FAILED =
  "SEO_USER_SERVICES_DETAILS_RECEIVED";
export const USER_PAYMENT_REQUESTED = "USER_PAYMENT_REQUESTED";
export const USER_PAYMENT_RECEIVED = "USER_PAYMENT_RECEIVED";
export const USER_PAYMENT_FAILED = "USER_PAYMENT_FAILED";
export const LEAD_GEN_REQUESTED = "LEAD_GEN_REQUESTED";
export const LEAD_GEN_RECEIVED = "LEAD_GEN_RECEIVED";
export const LEAD_GEN_FAILED = "LEAD_GEN_FAILED";

export const UPDATE_SELECTED_SEO_PACKAGE = "UPDATE_SELECTED_SEO_PACKAGE";
export const UPDATE_ACTIVE_STEP_SEO_DETAILS = "UPDATE_ACTIVE_STEP_SEO_DETAILS";

export const DASHBOARD_DATA_REQUESTED = "DASHBOARD_DATA_REQUESTED";
export const DASHBOARD_DATA_RECEIVED = "DASHBOARD_DATA_RECEIVED";
export const DASHBOARD_DATA_FAILED = "DASHBOARD_DATA_FAILED";
export const DASHBOARD_VIEW_VALUE_UPDATED = "DASHBOARD_VIEW_VALUE_UPDATED";
export const DASHBOARD_VIEW_VALUE_TRACK = "DASHBOARD_VIEW_VALUE_TRACK";

export const UPDATE_PRODUCT_TOUR_TRACK = "UPDATE_PRODUCT_TOUR_TRACK";

export const LOCAL_CLIENT = 0;
export const NON_UPS_CLIENT = 1;
export const UPS_CLIENT = 2;
export const UPS_CLIENT_WITH_CTP = 3;
export const NON_UPS_DIRECT_CLIENT = 4;
export const EX_CTP = 5;
export const GRADER_USERS = 9;
export const PROVISIONAL_USER = 10;
export const GOOGLE = 1;
export const BING = 2;
export const ON_BOARDING_IN_PROGRESS = "On Boarding in Progress";
export const NOT_YET_REGISTERED = "Not yet Registered";
export const ADMIN_USER_TABLE = "ADMIN_USER_TABLE";
export const ADMIN_USER_TABLE_SEARCH_TERM = "ADMIN_USER_TABLE_SEARCH_TERM";
export const REQUEST_DEMO_ROUTE = "requestDemo";
export const REQUEST_JUMPSTART_ROUTE = "requestJumpStart";
export const ADMIN_USER_TABLE_ROUTE = "adminUserTable";
export const ON_DEMAND_SERVICE_PAGE = "ON_DEMAND_SERVICE_PAGE";
export const GOOGLE_ADS_GRADER = "GOOGLE_ADS_GRADER";
export const GOOGLE_ADS_GRADER_HOME_PAGE = "GOOGLE_ADS_GRADER_HOME_PAGE";
export const FREE_TRIAL_DASHBOARD = "FREE_TRIAL_DASHBOARD";
export const SITE_GRADER_HOME = "SITE_GRADER_HOME";
export const PROPERTIES_AND_VIEWS = "PROPERTIES_AND_VIEWS";
export const SEO_DASHBOARD = "SEO_DASHBOARD";
export const ACCOUNT_SETTINGS = "ACCOUNT_SETTINGS";
export const PROFILE_SETTINGS = "PROFILE_SETTINGS";
export const CLIENT_SETTINGS = "CLIENT_SETTINGS";
export const PAYMENT_HISTORY = "PAYMENT_HISTORY";
export const TOGGLE_SELECTION = "TOGGLE_SELECTION";
export const TOGGLE_SELECTION_FOR_SERVICE_PACK =
  "TOGGLE_SELECTION_FOR_SERVICE_PACK";
export const HOME_PAGE = "HOME_PAGE";
export const PRICING_PAGE = "PRICING_PAGE";
export const FEATURES_PAGE = "FEATURES_PAGE";
export const GROWTH_MODEL_PAGE = "GROWTH_MODEL_PAGE";
export const EXTERNAL_SEO_RESULT_PAGE = "EXTERNAL_SEO_RESULT_PAGE";
export const FREE_TOOLS_PAGE = "FREE_TOOLS_PAGE";
export const LEAD_GEN_PAGE = "LEAD_GEN_PAGE";
export const ABOUT_US_PAGE = "ABOUT_US_PAGE";
export const UPDATE_LEAD_GENERATION = "UPDATE_LEAD_GENERATION";
export const CLOSE_MARTCH = "CLOSE_MARTCH";
export const OPEN_MARTCH = "OPEN_MARTCH";

export const CHANGE_ORGANIZED_BY = "CHANGE_ORGANIZED_BY";
export const POST_TRIGGER = "POST_TRIGGER";
export const EXPANSION_STATUS = "EXPANSION_STATUS";
export const FLOATING_CARD_TRACK = "FLOATING_CARD_TRACK";
export const FEEDBACK_TRACK = "FEEDBACK_TRACK";
export const DASHBOARD_PAGE_TRACK = "DASHBOARD_PAGE_TRACK";
export const TASK_PAGE_TRACK = "TASK_PAGE_TRACK";
export const REQUEST_DEMO_TRACK = "REQUEST_DEMO_TRACK";
export const REQUEST_JUMPSTART_TRACK = "REQUEST_JUMPSTART_TRACK";
export const REQUEST_EXPERT_HELP_TRACK = "REQUEST_EXPERT_HELP_TRACK";
export const MINI_SERVICE_PAGE_TRACK = "MINI_SERVICE_PAGE_TRACK";
export const SITE_GRADER_PAGE = "SITE_GRADER_PAGE";
export const TASKS_TABS_TRACK = "TASKS_TABS_TRACK";
export const TASK_DATA_TRACK = "TASK_DATA_TRACK";
export const HOLIDAY_PACKAGE_TRACK = "HOLIDAY_PACKAGE_TRACK";
export const GROWTH_MODEL_PAGE_TRACK = "GROWTH_MODEL_PAGE_TRACK";
export const FEATURES_PAGE_TRACK = "FEATURES_PAGE_TRACK";
export const FREE_TOOLS_PAGE_TRACK = "FREE_TOOLS_PAGE_TRACK";
export const GOOGLE_ADS_GRADER_HOME_TRACK = "GOOGLE_ADS_GRADER_HOME_TRACK";
export const SEO_SITE_GRADER_HOME_TRACK = "SEO_SITE_GRADER_HOME_TRACK";
export const PRICING_PAGE_TRACK = "PRICING_PAGE_TRACK";
export const REQUEST_DEMO_FORM_SUBMIT_TRACK = "REQUEST_DEMO_FORM_SUBMIT_TRACK";
export const GOOGLE_ADS_FORM_SUBMIT_TRACK = "GOOGLE_ADS_FORM_SUBMIT_TRACK";
export const SEO_SITE_GRADER_FORM_SUBMIT_TRACK =
  "SEO_SITE_GRADER_FORM_SUBMIT_TRACK";
export const LEAD_GEN_TRACK = "LEAD_GEN_TRACK";

//Jumpstart
export const JUMPSTART_REVAMPED = "JUMPSTART_REVAMPED";
export const JUMPSTART_USER_MANAGEMENT = "JUMPSTART_USER_MANAGEMENT";
export const JUMPSTART_ACCOUNT_MANAGEMENT = "JUMPSTART_ACCOUNT_MANAGEMENT";

export const ACCOUNT_TASKS_REQUESTED = "ACCOUNT_TASKS_REQUESTED";
export const ACCOUNT_TASKS_RECEIVED = "ACCOUNT_TASKS_RECEIVED";
export const ACCOUNT_TASKS_FAILED = "ACCOUNT_TASKS_FAILED";

export const UPDATE_TASK_DISPLAY_REQUESTED = "UPDATE_TASK_DISPLAY_REQUESTED";
export const UPDATE_TASK_DISPLAY_RECEIVED = "UPDATE_TASK_DISPLAY_RECEIVED";
export const UPDATE_TASK_DISPLAY_FAILED = "UPDATE_TASK_DISPLAY_FAILED";

export const USER_INFORMATION_REQUESTED = "USER_INFORMATION_REQUESTED";
export const USER_INFORMATION_RECEIVED = "USER_INFORMATION_RECEIVED";
export const USER_INFORMATION_REQUEST_FAILED =
  "USER_INFORMATION_REQUEST_FAILED";
export const UPDATE_SELECTED_ACCOUNT_REQUESTED =
  "UPDATE_SELECTED_ACCOUNT_REQUESTED";
export const UPDATE_SELECTED_ACCOUNT_RECEIVED =
  "UPDATE_SELECTED_ACCOUNT_RECEIVED";
export const UPDATE_SELECTED_ACCOUNT_FAILED = "UPDATE_SELECTED_ACCOUNT_FAILED";
export const UPDATE_ACCOUNT_DETAILS_REQUESTED =
  "UPDATE_ACCOUNT_DETAILS_REQUESTED";
export const UPDATE_ACCOUNT_DETAILS_RECEIVED =
  "UPDATE_ACCOUNT_DETAILS_RECEIVED";
export const UPDATE_ACCOUNT_DETAILS_FAILED = "UPDATE_ACCOUNT_DETAILS_FAILED";
export const UPDATE_CLIENT_DETAILS_REQUESTED =
  "UPDATE_CLIENT_DETAILS_REQUESTED";
export const UPDATE_CLIENT_DETAILS_RECEIVED = "UPDATE_CLIENT_DETAILS_RECEIVED";
export const UPDATE_CLIENT_DETAILS_FAILED = "UPDATE_CLIENT_DETAILS_FAILED";
export const UPDATE_SELECTED_CLIENT_REQUESTED =
  "UPDATE_SELECTED_CLIENT_REQUESTED";
export const UPDATE_SELECTED_CLIENT_RECEIVED =
  "UPDATE_SELECTED_CLIENT_RECEIVED";
export const UPDATE_SELECTED_CLIENT_FAILED = "UPDATE_SELECTED_CLIENT_FAILED";
export const PAYMENT_DETAILS_REQUESTED = "PAYMENT_DETAILS_REQUESTED";
export const PAYMENT_DETAILS_RECEIVED = "PAYMENT_DETAILS_RECEIVED";
export const PAYMENT_DETAILS_FAILED = "PAYMENT_DETAILS_FAILED";
export const UPDATE_PROFILE_DETAILS_REQUESTED =
  "UPDATE_PROFILE_DETAILS_REQUESTED";
export const UPDATE_PROFILE_DETAILS_RECEIVED =
  "UPDATE_PROFILE_DETAILS_RECEIVED";
export const UPDATE_PROFILE_DETAILS_FAILED = "UPDATE_PROFILE_DETAILS_FAILED";
export const FETCH_PROFILE_DETAILS_REQUESTED =
  "FETCH_PROFILE_DETAILS_REQUESTED";
export const FETCH_PROFILE_DETAILS_RECEIVED = "FETCH_PROFILE_DETAILS_RECEIVED";
export const FETCH_PROFILE_DETAILS_FAILED = "FETCH_PROFILE_DETAILS_FAILED";
export const DELETE_CARD_DETAILS_REQUESTED = "DELETE_CARD_DETAILS_REQUESTED";
export const DELETE_CARD_DETAILS_RECEIVED = "DELETE_CARD_DETAILS_RECEIVED";
export const DELETE_CARD_DETAILS_FAILED = "DELETE_CARD_DETAILS_FAILED";
export const ADD_CARD_DETAILS_REQUESTED = "ADD_CARD_DETAILS_REQUESTED";
export const ADD_CARD_DETAILS_RECEIVED = "ADD_CARD_DETAILS_RECEIVED";
export const ADD_CARD_DETAILS_FAILED = "ADD_CARD_DETAILS_FAILED";
export const APPLY_PROMO_CODE_DETAILS_REQUESTED =
  "APPLY_PROMO_CODE_DETAILS_REQUESTED";
export const APPLY_PROMO_CODE_DETAILS_RECEIVED =
  "APPLY_PROMO_CODE_DETAILS_RECEIVED";
export const APPLY_PROMO_CODE_DETAILS_FAILED =
  "APPLY_PROMO_CODE_DETAILS_FAILED";
export const SUBSCRIBE_DETAILS_REQUESTED = "SUBSCRIBE_DETAILS_REQUESTED";
export const SUBSCRIBE_DETAILS_RECEIVED = "SUBSCRIBE_DETAILS_RECEIVED";
export const SUBSCRIBE_DETAILS_FAILED = "SUBSCRIBE_DETAILS_FAILED";
export const RESUME_ACCOUNT_REQUESTED = "RESUME_ACCOUNT_REQUESTED";
export const RESUME_ACCOUNT_RECEIVED = "RESUME_ACCOUNT_RECEIVED";
export const RESUME_ACCOUNT_FAILED = "RESUME_ACCOUNT_FAILED";
export const UNSUBSCRIBE_ACCOUNT_REQUESTED = "UNSUBSCRIBE_ACCOUNT_REQUESTED";
export const UNSUBSCRIBE_ACCOUNT_RECEIVED = "UNSUBSCRIBE_ACCOUNT_RECEIVED";
export const UNSUBSCRIBE_ACCOUNT_FAILED = "UNSUBSCRIBE_ACCOUNT_FAILED";

export const UPDATE_JS_ACCOUNT_REQUESTED = "UPDATE_JS_ACCOUNT_REQUESTED";
export const UPDATE_JS_ACCOUNT_RECEIVED = "UPDATE_JS_ACCOUNT_RECEIVED";
export const UPDATE_JS_ACCOUNT_FAILED = "UPDATE_JS_ACCOUNT_FAILED";

export const AUTH_URLS_REQUESTED = "AUTH_URLS_REQUESTED";
export const AUTH_URLS_RECEIVED = "AUTH_URLS_RECEIVED";
export const AUTH_URLS_FAILED = "AUTH_URLS_FAILED";

export const SCROLL_HANDLER_START = "SCROLL_HANDLER_START";
export const SCROLL_HANDLER_ENDED = "SCROLL_HANDLER_ENDED";
export const SCROLL_HANDLER_FAILED = "SCROLL_HANDLER_FAILED";

export const TOGGLE_WELCOME_BANNER = "TOGGLE_WELCOME_BANNER";
export const CANCEL_WELCOME_BANNER = "CANCEL_WELCOME_BANNER";
export const TOGGLE_THANK_YOU_BANNER = "TOGGLE_THANK_YOU_BANNER";
export const ON_PRODUCT_TOUR_CLOSE = "ON_PRODUCT_TOUR_CLOSE";
export const ON_PRODUCT_TOUR_CLOSE_CROSS = "ON_PRODUCT_TOUR_CLOSE_CROSS";

export const Account_Import_STATUS_REQUESTED =
  "Account_Import_STATUS_REQUESTED";
export const Account_Import_STATUS_RECEIVED = "Account_Import_STATUS_RECEIVED";
export const Account_Import_STATUS_FAILED = "Account_Import_STATUS_FAILED";

export const UPDATE_REFRESH_ACCESS_SUCCESS = "UPDATE_REFRESH_ACCESS_SUCCESS";

export const SUPPORT_MAIL_FOR_CONTENT = "support@lxrguide.com";
export const UPDATE_DRAWER = "UPDATE_DRAWER";
export const UPDATE_MENU = "UPDATE_MENU";
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const RESET_MENU = "RESET_MENU";

export const ACTIVITY_REPORT_REQUESTED = "ACTIVITY_REPORT_REQUESTED";
export const ACTIVITY_REPORT_RECEIVED = "ACTIVITY_REPORT_RECEIVED";
export const ACTIVITY_REPORT_FAILED = "ACTIVITY_REPORT_FAILED";
export const ACTIVITY_REPORT_TASK_DATA_REQUESTED =
  "ACTIVITY_REPORT_TASK_DATA_REQUESTED";
export const ACTIVITY_REPORT_TASK_DATA_RECEIVED =
  "ACTIVITY_REPORT_TASK_DATA_RECEIVED";
export const ACTIVITY_REPORT_TASK_DATA_FAILED =
  "ACTIVITY_REPORT_TASK_DATA_FAILED";
export const TOGGLE_WEEKLY_DATE_RANGE = "TOGGLE_WEEKLY_DATE_RANGE";
export const UPDATE_SELECTED_NODE_KEY = "UPDATE_SELECTED_NODE_KEY";

export const SUBSCRIPTIONS_REQUESTED = "SUBSCRIPTIONS_REQUESTED";
export const SUBSCRIPTIONS_RECEIVED = "SUBSCRIPTIONS_RECEIVED";
export const SUBSCRIPTIONS_FAILED = "SUBSCRIPTIONS_FAILED";

export const UN_SUBSCRIPTIONS_REQUESTED = "UN_SUBSCRIPTIONS_REQUESTED";
export const UN_SUBSCRIPTIONS_RECEIVED = "UN_SUBSCRIPTIONS_RECEIVED";
export const UN_SUBSCRIPTIONS_FAILED = "UN_SUBSCRIPTIONS_FAILED";

/*export const UN_SUBSCRIPTIONS_REQUESTED = "UN_SUBSCRIPTIONS_REQUESTED";
export const UN_SUBSCRIPTIONS_RECEIVED = "UN_SUBSCRIPTIONS_RECEIVED";
export const UN_SUBSCRIPTIONS_FAILED = "UN_SUBSCRIPTIONS_FAILED";*/
export const CONTRACT_SIGNED_REQUESTED = "CONTRACT_SIGNED_REQUESTED";
export const CONTRACT_SIGNED_RECEIVED = "CONTRACT_SIGNED_RECEIVED";
export const CONTRACT_SIGNED_FAILED = "CONTRACT_SIGNED_FAILED";

export const DASHBOARD_NOTIFICATION_ID = 1;
export const REPORT_NOTIFICATION_ID = 2;
export const TASK_NOTIFICATION_ID = 3;
export const FIRST_TASK_NOTIFICATION_ID = 4;
export const TOUR_END_NOTIFICATION_ID = 5;
// todo take the id from db after new notification type is added
export const ACTIVITY_REPORT_ID = 0;
export const JUMP_START = 7;
export const STANDARD = 8;
export const ZOOM_LINK = "https://zoom.us/j/553375291";
export const AGENCY_PROFESSIONAL = 11;
export const AGENCY_PROFESSIONAL_PLUS = 15;
export const AGENCY_ELITE = 12;
export const AGENCY_PREMIER = 13;
export const CTP = 14;
export const STANDARD_PLUS = 16;

export const PURCHASED = "Purchased";
export const PROGRESS = "Progress";
export const Delivered = "Delivered";
export const ACCEPTED = "Accepted";

export const EXTERNAL_SEO = 0;
export const INTERNAL_SEO = 1;

export const COMPLETED = "COMPLETED";
export const REVIEWER_APPROVED = "REVIEWER_APPROVED";
export const CLIENT_APPROVED = "CLIENT_APPROVED";

export const ONE_TIME = "one-time";
export const SUBSCRIPTION = "subscribe";

export const FETCH_AUTO_TASK_DATA_REQUESTED = "FETCH_AUTO_TASK_DATA_REQUESTED";
export const FETCH_AUTO_TASK_DATA_RECEIVED = "FETCH_AUTO_TASK_DATA_RECEIVED";
export const FETCH_AUTO_TASK_DATA_FAILED = "FETCH_AUTO_TASK_DATA_FAILED";

export const SAVE_AUTO_TASK_DATA_REQUESTED = "SAVE_AUTO_TASK_DATA_REQUESTED";
export const SAVE_AUTO_TASK_DATA_RECEIVED = "SAVE_AUTO_TASK_DATA_RECEIVED";
export const SAVE_AUTO_TASK_DATA_FAILED = "SAVE_AUTO_TASK_DATA_FAILED";

export const FETCH_INDUSTRY_INSIGHT_DATA_REQUESTED =
  "FETCH_INDUSTRY_INSIGHT_DATA_REQUESTED";
export const FETCH_INDUSTRY_INSIGHT_DATA_RECEIVED =
  "FETCH_INDUSTRY_INSIGHT_DATA_RECEIVED";
export const FETCH_INDUSTRY_INSIGHT_DATA_FAILED =
  "FETCH_INDUSTRY_INSIGHT_DATA_FAILED";

export const FETCH_INDUSTRY_DATA_REQUESTED = "FETCH_INDUSTRY_DATA_REQUESTED";
export const FETCH_INDUSTRY_DATA_RECEIVED = "FETCH_INDUSTRY_DATA_RECEIVED";
export const FETCH_INDUSTRY_DATA_FAILED = "FETCH_INDUSTRY_DATA_FAILED";

export const FETCH_GOOGLE_ANALY_URL_DATA_REQUESTED =
  "FETCH_GOOGLE_ANALY_URL_DATA_REQUESTED";
export const FETCH_GOOGLE_ANALY_URL_DATA_RECEIVED =
  "FETCH_GOOGLE_ANALY_URL_DATA_RECEIVED";
export const FETCH_GOOGLE_ANALY_URL_DATA_FAILED =
  "FETCH_GOOGLE_ANALY_URL_DATA_FAILED";

export const FETCH_CONNECTION_DATA_REQUESTED =
  "FETCH_CONNECTION_DATA_REQUESTED";
export const FETCH_CONNECTION_DATA_RECEIVED = "FETCH_CONNECTION_DATA_RECEIVED";
export const FETCH_CONNECTION_DATA_FAILED = "FETCH_CONNECTION_DATA_FAILED";

export const DELETE_ANALY_CONNECTION_REQUESTED =
  "DELETE_ANALY_CONNECTION_REQUESTED";
export const DELETE_ANALY_CONNECTION_RECEIVED =
  "DELETE_ANALY_CONNECTION_RECEIVED";
export const DELETE_ANALY_CONNECTION_FAILED = "DELETE_ANALY_CONNECTION_FAILED";

export const FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_REQUESTED =
  "FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_REQUESTED";
export const FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_RECEIVED =
  "FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_RECEIVED";
export const FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_FAILED =
  "FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_FAILED";

export const FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_REQUESTED =
  "FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_REQUESTED";
export const FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_RECEIVED =
  "FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_RECEIVED";
export const FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_FAILED =
  "FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_FAILED";
// GA = Google Analytics
export const FETCH_GA_MODEL_DATA_REQUESTED = "FETCH_GA_MODEL_DATA_REQUESTED";
export const FETCH_GA_MODEL_DATA_RECEIVED = "FETCH_GA_MODEL_DATA_RECEIVED";
export const FETCH_GA_MODEL_DATA_FAILED = "FETCH_GA_MODEL_DATA_FAILED";

export const FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_REQUESTED =
  "FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_REQUESTED";
export const FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_RECEIVED =
  "FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_RECEIVED";
export const FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_FAILED =
  "FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_FAILED";

export const STORE_ANALY_USER_ACC_DATA_REQUESTED =
  "STORE_ANALY_USER_ACC_DATA_REQUESTED";
export const STORE_ANALY_USER_ACC_DATA_RECEIVED =
  "STORE_ANALY_USER_ACC_DATA_RECEIVED";
export const STORE_ANALY_USER_ACC_DATA_FAILED =
  "STORE_ANALY_USER_ACC_DATA_FAILED";

export const FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_REQUESTED =
  "FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_REQUESTED";
export const FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_RECEIVED =
  "FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_RECEIVED";
export const FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_FAILED =
  "FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_FAILED";

export const FETCH_SYNCHECKID_REQUESTED = "FETCH_SYNCHECKID_REQUESTED";
export const FETCH_SYNCHECKID_RECEIVED = "FETCH_SYNCHECKID_RECEIVED";
export const FETCH_SYNCHECKID_FAILED = "FETCH_SYNCHECKID_FAILED";

export const FETCH_STATUS_FOR_PROGRESS_BAR_REQUESTED =
  "FETCH_STATUS_FOR_PROGRESS_BAR_REQUESTED";
export const FETCH_STATUS_FOR_PROGRESS_BAR_RECEIVED =
  "FETCH_STATUS_FOR_PROGRESS_BAR_RECEIVED";
export const FETCH_STATUS_FOR_PROGRESS_BAR_FAILED =
  "FETCH_STATUS_FOR_PROGRESS_BAR_FAILED";

export const FETCH_DATE_RANGE_REQUESTED = "FETCH_DATE_RANGE_REQUESTED";
export const FETCH_DATE_RANGE_RECEIVED = "FETCH_DATE_RANGE_RECEIVED";
export const FETCH_DATE_RANGE_FAILED = "FETCH_DATE_RANGE_FAILED";

export const CLOSE_ANALYTICS_BANNER = "CLOSE_ANALYTICS_BANNER";
export const OPEN_ANALYTICS_BANNER = "OPEN_ANALYTICS_BANNER";

export const FETCH_HEALTH_SCORECARD_DATA_REQUESTED =
  "FETCH_HEALTH_SCORECARD_DATA_REQUESTED";
export const FETCH_HEALTH_SCORECARD_DATA_RECEIVED =
  "FETCH_HEALTH_SCORECARD_DATA_RECEIVED";
export const FETCH_HEALTH_SCORECARD_DATA_FAILED =
  "FETCH_HEALTH_SCORECARD_DATA_FAILED";

export const FETCH_HEALTH_SCORECARD_EMAIL_DATA_REQUESTED =
  "FETCH_HEALTH_SCORECARD_EMAIL_DATA_REQUESTED";
export const FETCH_HEALTH_SCORECARD_EMAIL_DATA_RECEIVED =
  "FETCH_HEALTH_SCORECARD_EMAIL_DATA_RECEIVED";
export const FETCH_HEALTH_SCORECARD_EMAIL_DATA_FAILED =
  "FETCH_HEALTH_SCORECARD_EMAIL_DATA_FAILED";

export const WHS_EXPERT_DATA_REQUESTED = "WHS_EXPERT_DATA_REQUESTED";
export const WHS_EXPERT_DATA_RECEIVED = "WHS_EXPERT_DATA_RECEIVED";
export const WHS_EXPERT_DATA_FAILED = "WHS_EXPERT_DATA_FAILED";

export const WHS_REFRESH_DATA_REQUESTED = "WHS_REFRESH_DATA_REQUESTED";
export const WHS_REFRESH_DATA_RECEIVED = "WHS_REFRESH_DATA_RECEIVED";
export const WHS_REFRESH_DATA_FAILED = "WHS_REFRESH_DATA_FAILED";

export const ON_DEMAND_PACKAGE_REQUESTED = "ON_DEMAND_PACKAGE_REQUESTED";
export const ON_DEMAND_PACKAGE_RECEIVED = "ON_DEMAND_PACKAGE_RECEIVED";
export const ON_DEMAND_PACKAGE_FAILED = "ON_DEMAND_PACKAGE_FAILED";

export const WHS_REFRESH_DOWNLOAD_STATUS_REQUESTED =
  "WHS_REFRESH_DOWNLOAD_STATUS_REQUESTED";
export const WHS_REFRESH_DOWNLOAD_STATUS_RECEIVED =
  "WHS_REFRESH_DOWNLOAD_STATUS_RECEIVED";
export const WHS_REFRESH_DOWNLOAD_STATUS_FAILED =
  "WHS_REFRESH_DOWNLOAD_STATUS_FAILED";

export const WHS_USER_LOG_DATA_REQUESTED = "WHS_USER_LOG_DATA_REQUESTED";
export const WHS_USER_LOG_DATA_RECEIVED = "WHS_USER_LOG_DATA_RECEIVED";
export const WHS_USER_LOG_DATA_FAILED = "WHS_USER_LOG_DATA_FAILED";

export const DELETE_BING_ADS_REQUESTED = "DELETE_BING_ADS_REQUESTED";
export const DELETE_BING_ADS_RECEIVED = "DELETE_BING_ADS_RECEIVED";
export const DELETE_BING_ADS_FAILED = "DELETE_BING_ADS_FAILED";

export const DELETE_GLE_ADS_REQUESTED = "DELETE_GLE_ADS_REQUESTED";
export const DELETE_GLE_ADS_RECEIVED = "DELETE_GLE_ADS_RECEIVED";
export const DELETE_GLE_ADS_FAILED = "DELETE_GLE_ADS_FAILED";

export const DELETE_GMC_REQUESTED = "DELETE_GMC_REQUESTED";
export const DELETE_GMC_RECEIVED = "DELETE_GMC_RECEIVED";
export const DELETE_GMC_FAILED = "DELETE_GMC_FAILED";

export const DELETE_SHOPPING_FEED_REQUESTED = "DELETE_SHOPPING_FEED_REQUESTED";
export const DELETE_SHOPPING_FEED_RECEIVED = "DELETE_SHOPPING_FEED_RECEIVED";
export const DELETE_SHOPPING_FEED_FAILED = "DELETE_SHOPPING_FEED_FAILED";

export const GMC_AUTH_URL_REQUESTED = "GMC_AUTH_URL_REQUESTED";
export const GMC_AUTH_URL_RECEIVED = "GMC_AUTH_URL_RECEIVED";
export const GMC_AUTH_URL_FAILED = "GMC_AUTH_URL_FAILED";
export const WHS_EXTERNAL_DATA_REQUESTED = "WHS_EXTERNAL_DATA_REQUESTED";
export const WHS_EXTERNAL_DATA_RECEIVED = "WHS_EXTERNAL_DATA_RECEIVED";
export const WHS_EXTERNAL_DATA_FAILED = "WHS_EXTERNAL_DATA_FAILED";

export const DELETE_SEARCH_CONSOLE_REQUESTED = "DELETE_SEARCH_CONSOLE_REQUESTED";
export const DELETE_SEARCH_CONSOLE_RECEIVED = "DELETE_SEARCH_CONSOLE_RECEIVED";
export const DELETE_SEARCH_CONSOLE_FAILED = "DELETE_SEARCH_CONSOLE_FAILED";

export const PROMOCODE_CONFIRMATION_REQUESTED = "PROMOCODE_CONFIRMATION_REQUESTED";
export const PROMOCODE_CONFIRMATION_RECEIVED = "PROMOCODE_CONFIRMATION_RECEIVED";
export const PROMOCODE_CONFIRMATION_FAILED = "PROMOCODE_CONFIRMATION_FAILED";

export const GMC_DETAILS_REQUESTED = "GMC_DETAILS_REQUESTED";
export const GMC_DETAILS_RECEIVED = "GMC_DETAILS_RECEIVED";
export const GMC_DETAILS_FAILED = "GMC_DETAILS_FAILED";

export const FETCH_SEARCH_CONSOLE_ACCOUNT_REQUESTED = "FETCH_SEARCH_CONSOLE_ACCOUNT_REQUESTED" ;
export const FETCH_SEARCH_CONSOLE_ACCOUNT_RECEIVED = "FETCH_SEARCH_CONSOLE_ACCOUNT_RECEIVED" ;
export const FETCH_SEARCH_CONSOLE_ACCOUNT_FAILED = "FETCH_SEARCH_CONSOLE_ACCOUNT_FAILED" ;

export const JS_AUTHENTICATE_USER_REQUESTED = "JS_AUTHENTICATE_USER_REQUESTED";
export const JS_AUTHENTICATE_USER_RECEIVED = "JS_AUTHENTICATE_USER_RECEIVED";
export const JS_AUTHENTICATE_USER_FAILED = "JS_AUTHENTICATE_USER_FAILED";

export const JS_ACCOUNTS_REQUESTED = "JS_ACCOUNTS_REQUESTED";
export const JS_ACCOUNTS_RECEIVED = "JS_ACCOUNTS_RECEIVED";
export const JS_ACCOUNTS_FAILED = "JS_ACCOUNTS_FAILED";

export const JS_USER_INFO_REQUESTED = "JS_USER_INFO_REQUESTED";
export const JS_USER_INFO_RECEIVED = "JS_USER_INFO_RECEIVED";
export const JS_USER_INFO_FAILED = "JS_USER_INFO_FAILED";

export const JS_ALL_USER_DETAILS_REQUESTED = "JS_ALL_USER_DETAILS_REQUESTED";
export const JS_ALL_USER_DETAILS_RECEIVED = "JS_ALL_USER_DETAILS_RECEIVED";
export const JS_ALL_USER_DETAILS_FAILED = "JS_ALL_USER_DETAILS_FAILED";


export const JS_ACCOUNT_BY_ID_REQUESTED = "JS_ACCOUNT_BY_ID_REQUESTED";
export const JS_ACCOUNT_BY_ID_RECEIVED = "JS_ACCOUNT_BY_ID_RECEIVED";
export const JS_ACCOUNT_BY_ID_FAILED = "JS_ACCOUNT_BY_ID_FAILED";

export const JS_ADD_USER_REQUESTED = "JS_ADD_USER_REQUESTED"
export const JS_ADD_USER_RECEIVED = "JS_ADD_USER_RECEIVED"
export const JS_ADD_USER_FAILED = "JS_ADD_USER_FAILED"

export const JS_UPDATE_USER_REQUESTED = "JS_UPDATE_USER_REQUESTED"
export const JS_UPDATE_USER_RECEIVED = "JS_UPDATE_USER_RECEIVED"
export const JS_UPDATE_USER_FAILED = "JS_UPDATE_USER_FAILED"

export const JS_DELETE_USER_REQUESTED = "JS_DELETE_USER_REQUESTED"
export const JS_DELETE_USER_RECEIVED = "JS_DELETE_USER_RECEIVED"
export const JS_DELETE_USER_FAILED = "JS_DELETE_USER_FAILED"


export const PASSWORD_CHANGE_REQUESTED = "PASSWORD_CHANGE_REQUESTED"
export const PASSWORD_CHANGE_RECEIVED = "PASSWORD_CHANGE_RECEIVED"
export const PASSWORD_CHANGE_FAILED = "PASSWORD_CHANGE_FAILED"

export const FINISH_PASSWORD_RESET_REQUESTED = "FINISH_PASSWORD_RESET_REQUESTED"
export const FINISH_PASSWORD_RESET_RECEIVED = "FINISH_PASSWORD_RESET_RECEIVED"
export const FINISH_PASSWORD_RESET_FAILED = "FINISH_PASSWORD_RESET_FAILED"

export const UPDATE_BUSINESS_DETAILS_REQUESTED = "UPDATE_BUSINESS_DETAILS_REQUESTED"
export const UPDATE_BUSINESS_DETAILS_RECEIVED = "UPDATE_BUSINESS_DETAILS_RECEIVED"
export const UPDATE_BUSINESS_DETAILS_FAILED = "UPDATE_BUSINESS_DETAILS_FAILED"


export const JS_BUSINESS_DETAILS_BY_ID_REQUESTED = "JS_BUSINESS_DETAILS_BY_ID_REQUESTED"
export const JS_BUSINESS_DETAILS_BY_ID_RECEIVED = "JS_BUSINESS_DETAILS_BY_ID_RECEIVED"
export const JS_BUSINESS_DETAILS_BY_ID_FAILED = "JS_BUSINESS_DETAILS_BY_ID_FAILED"

export const UPDATE_STORE_CAMPAIGN_DETAILS_REQUESTED = "UPDATE_STORE_CAMPAIGN_DETAILS_REQUESTED"
export const UPDATE_STORE_CAMPAIGN_DETAILS_RECEIVED = "UPDATE_STORE_CAMPAIGN_DETAILS_RECEIVED"
export const UPDATE_STORE_CAMPAIGN_DETAILS_FAILED = "UPDATE_STORE_CAMPAIGN_DETAILS_FAILED"

export const FETCH_JS_SYNC_CHECK_ID_REQUESTED = "FETCH_JS_SYNC_CHECK_ID_REQUESTED"
export const FETCH_JS_SYNC_CHECK_ID_RECEIVED = "FETCH_JS_SYNC_CHECK_ID_RECEIVED"
export const FETCH_JS_SYNC_CHECK_ID_FAILED = "FETCH_JS_SYNC_CHECK_ID_FAILED"

export const CREATE_ACCOUNT_STRUCTURE_REQUESTED = "CREATE_ACCOUNT_STRUCTURE_REQUESTED"
export const CREATE_ACCOUNT_STRUCTURE_RECEIVED = "CREATE_ACCOUNT_STRUCTURE_RECEIVED"
export const CREATE_ACCOUNT_STRUCTURE_FAILED = "CREATE_ACCOUNT_STRUCTURE_FAILED"

export const FETCH_CALLOUTS_REQUESTED = "FETCH_CALLOUTS_REQUESTED"
export const FETCH_CALLOUTS_RECEIVED = "FETCH_CALLOUTS_RECEIVED"
export const FETCH_CALLOUTS_FAILED = "FETCH_CALLOUTS_FAILED"

export const NEW_GENERATED_CALLOUTS_REQUESTED = "NEW_GENERATED_CALLOUTS_REQUESTED"
export const NEW_GENERATED_CALLOUTS_RECEIVED = "NEW_GENERATED_CALLOUTS_RECEIVED"
export const NEW_GENERATED_CALLOUTS_FAILED = "NEW_GENERATED_CALLOUTS_FAILED"

export const SAVE_CALLOUTS_REQUESTED = "SAVE_CALLOUTS_REQUESTED"
export const SAVE_CALLOUTS_RECEIVED = "SAVE_CALLOUTS_RECEIVED"
export const SAVE_CALLOUTS_FAILED = "SAVE_CALLOUTS_FAILED"

export const FETCH_SITELINKS_REQUESTED = "FETCH_SITELINKS_REQUESTED"
export const FETCH_SITELINKS_RECEIVED = "FETCH_SITELINKS_RECEIVED"
export const FETCH_SITELINKS_FAILED = "FETCH_SITELINKS_FAILED"

export const NEW_GENERATED_SITELINKS_REQUESTED = "NEW_GENERATED_SITELINKS_REQUESTED"
export const NEW_GENERATED_SITELINKS_RECEIVED = "NEW_GENERATED_SITELINKS_RECEIVED"
export const NEW_GENERATED_SITELINKS_FAILED = "NEW_GENERATED_SITELINKS_FAILED"

export const SAVE_SITELINKS_REQUESTED = "SAVE_SITELINKS_REQUESTED"
export const SAVE_SITELINKS_RECEIVED = "SAVE_SITELINKS_RECEIVED"
export const SAVE_SITELINKS_FAILED = "SAVE_SITELINKS_FAILED"

export const FETCH_CAMPAIGN_DETAILS_REQUESTED = "FETCH_CAMPAIGN_DETAILS_REQUESTED"
export const FETCH_CAMPAIGN_DETAILS_RECEIVED = "FETCH_CAMPAIGN_DETAILS_RECEIVED"
export const FETCH_CAMPAIGN_DETAILS_FAILED = "FETCH_CAMPAIGN_DETAILS_FAILED"


export const FETCH_RESPONSIVE_ADS_REQUESTED = "FETCH_RESPONSIVE_ADS_REQUESTED"
export const FETCH_RESPONSIVE_ADS_RECEIVED = "FETCH_RESPONSIVE_ADS_RECEIVED"
export const FETCH_RESPONSIVE_ADS_FAILED = "FETCH_RESPONSIVE_ADS_FAILED"

export const UPDATE_RSA_REQUESTED = "UPDATE_RESPONSIVE_ADS_REQUESTED"
export const UPDATE_RSA_RECEIVED = "UPDATE_RESPONSIVE_ADS_RECEIVED"
export const UPDATE_RSA_FAILED = "UPDATE_RESPONSIVE_ADS_FAILED"

export const GENERATE_RSA_HEADLINES_REQUESTED = "GENERATE_RSA_HEADLINES_REQUESTED"
export const GENERATE_RSA_HEADLINES_RECEIVED = "GENERATE_RSA_HEADLINES_RECEIVED"
export const GENERATE_RSA_HEADLINES_FAILED = "GENERATE_RSA_HEADLINES_FAILED"

export const GENERATE_RSA_DESCRIPTIONS_REQUESTED = "GENERATE_RSA_DESCRIPTIONS_REQUESTED"
export const GENERATE_RSA_DESCRIPTIONS_RECEIVED = "GENERATE_RSA_DESCRIPTIONS_RECEIVED"
export const GENERATE_RSA_DESCRIPTIONS_FAILED = "GENERATE_RSA_DESCRIPTIONS_FAILED"

export const GENERATE_RSA_PATHS_REQUESTED = "GENERATE_RSA_PATHS_REQUESTED"
export const GENERATE_RSA_PATHS_RECEIVED = "GENERATE_RSA_PATHS_RECEIVED"
export const GENERATE_RSA_PATHS_FAILED = "GENERATE_RSA_PATHS_FAILED"

export const FETCH_KEYWORDS_REQUESTED = "FETCH_KEYWORDS_REQUESTED"
export const FETCH_KEYWORDS_RECEIVED = "FETCH_KEYWORDS_RECEIVED"
export const FETCH_KEYWORDS_FAILED = "FETCH_KEYWORDS_FAILED"

export const UPDATE_KEYWORDS_REQUESTED = "UPDATE_KEYWORDS_REQUESTED"
export const UPDATE_KEYWORDS_RECEIVED = "UPDATE_KEYWORDS_RECEIVED"
export const UPDATE_KEYWORDS_FAILED = "UPDATE_KEYWORDS_FAILED"

export const FETCH_ADGROUP_STRUCTURE_REQUESTED = "FETCH_ADGROUP_STRUCTURE_REQUESTED"
export const FETCH_ADGROUP_STRUCTURE_RECEIVED = "FETCH_ADGROUP_STRUCTURE_RECEIVED"
export const FETCH_ADGROUP_STRUCTURE_FAILED = "FETCH_ADGROUP_STRUCTURE_FAILED"

export const STORE_ADGROUP_DETAILS_REQUESTED = "STORE_ADGROUP_DETAILS_REQUESTED"
export const STORE_ADGROUP_DETAILS_RECEIVED = "STORE_ADGROUP_DETAILS_RECEIVED"
export const STORE_ADGROUP_DETAILS_FAILED = "STORE_ADGROUP_DETAILS_FAILED"


export const JS_CAMPAIGN_DETAILS_BY_ID_REQUESTED = "JS_CAMPAIGN_DETAILS_BY_ID_REQUESTED"
export const JS_CAMPAIGN_DETAILS_BY_ID_RECEIVED = "JS_CAMPAIGN_DETAILS_BY_ID_RECEIVED"
export const JS_CAMPAIGN_DETAILS_BY_ID_FAILED = "JS_CAMPAIGN_DETAILS_BY_ID_FAILED"

export const JS_ADGROUP_DETAILS_BY_ID_REQUESTED = "JS_ADGROUP_DETAILS_BY_ID_REQUESTED"
export const JS_ADGROUP_DETAILS_BY_ID_RECEIVED = "JS_ADGROUP_DETAILS_BY_ID_RECEIVED"
export const JS_ADGROUP_DETAILS_BY_ID_FAILED = "JS_ADGROUP_DETAILS_BY_ID_FAILED"


export const DELETE_ADGROUP_DETAILS_REQUESTED = "DELETE_ADGROUP_DETAILS_REQUESTED"
export const DELETE_ADGROUP_DETAILS_RECEIVED = "DELETE_ADGROUP_DETAILS_RECEIVED"
export const DELETE_ADGROUP_DETAILS_FAILED = "DELETE_ADGROUP_DETAILS_FAILED"

export const SUBMIT_CAMPAIGN_FRAMEWORK_REQUESTED = "SUBMIT_CAMPAIGN_FRAMEWORK_REQUESTED"
export const SUBMIT_CAMPAIGN_FRAMEWORK_RECEIVED = "SUBMIT_CAMPAIGN_FRAMEWORK_RECEIVED"
export const SUBMIT_CAMPAIGN_FRAMEWORK_FAILED = "SUBMIT_CAMPAIGN_FRAMEWORK_FAILED"

export const SUBMIT_CAMPAIGN_STRUCTURE_REQUESTED = "SUBMIT_CAMPAIGN_STRUCTURE_REQUESTED"
export const SUBMIT_CAMPAIGN_STRUCTURE_RECEIVED = "SUBMIT_CAMPAIGN_STRUCTURE_RECEIVED"
export const SUBMIT_CAMPAIGN_STRUCTURE_FAILED = "SUBMIT_CAMPAIGN_STRUCTURE_FAILED"

export const DOWNLOAD_CAMPAIGN_STRUCTURE_REQUESTED = "DOWNLOAD_CAMPAIGN_STRUCTURE_REQUESTED"
export const DOWNLOAD_CAMPAIGN_STRUCTURE_RECEIVED = "DOWNLOAD_CAMPAIGN_STRUCTURE_RECEIVED"
export const DOWNLOAD_CAMPAIGN_STRUCTURE_FAILED = "DOWNLOAD_CAMPAIGN_STRUCTURE_FAILED"

export const DOWNLOAD_CAMPAIGN_FRAMEWORK_REQUESTED = "DOWNLOAD_CAMPAIGN_FRAMEWORK_REQUESTED"
export const DOWNLOAD_CAMPAIGN_FRAMEWORK_RECEIVED = "DOWNLOAD_CAMPAIGN_FRAMEWORK_RECEIVED"
export const DOWNLOAD_CAMPAIGN_FRAMEWORK_FAILED = "DOWNLOAD_CAMPAIGN_FRAMEWORK_FAILED"

export const JS_ADGROUP_DETAILS_BY_ID_TEST_REQUESTED = "JS_ADGROUP_DETAILS_BY_ID_TEST_REQUESTED"
export const JS_ADGROUP_DETAILS_BY_ID_TEST_RECEIVED = "JS_ADGROUP_DETAILS_BY_ID_TEST_RECEIVED"
export const JS_ADGROUP_DETAILS_BY_ID_TEST_FAILED = "JS_ADGROUP_DETAILS_BY_ID_TEST_FAILED"

export const SYSTEM_MAINTENANCE_DATA_REQUESTED = "SYSTEM_MAINTENANCE_DATA_REQUESTED"
export const SYSTEM_MAINTENANCE_DATA_RECEIVED = "SYSTEM_MAINTENANCE_DATA_RECEIVED"
export const SYSTEM_MAINTENANCE_DATA_FAILED = "SYSTEM_MAINTENANCE_DATA_FAILED"

export const DELETE_CAMPAIGN_REQUESTED = "DELETE_CAMPAIGN_REQUESTED"
export const DELETE_CAMPAIGN_RECEIVED = "DELETE_CAMPAIGN_RECEIVED"
export const DELETE_CAMPAIGN_FAILED = "DELETE_CAMPAIGN_FAILED"

export const JS_ACCOUNT_RESET_STATUS_REQUESTED = "JS_ACCOUNT_RESET_STATUS_REQUESTED"
export const JS_ACCOUNT_RESET_STATUS_RECEIVED = "JS_ACCOUNT_RESET_STATUS_RECEIVED"
export const JS_ACCOUNT_RESET_STATUS_FAILED = "JS_ACCOUNT_RESET_STATUS_FAILED"
