import React, { useEffect, useState } from "react";
import { Chip, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import {
  JsSectionTypography,
  JsSubSectionTypography,
} from "../../../components/typography";
import LaunchIcon from "@mui/icons-material/Launch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchJsAccountById } from "../../../actions/jumpstartRevamped";
import { formatDate } from "../../commonFunctions/commonFunctions";
import { FormatAccountStatus } from "./formatStatus";
import "../jumpstart.css";
import { AccountStage, AccountStatus, Authority } from "./jumpstartEnum";
import ResetStatusDialog from "./resetStatus";
import { resetJsAccountStatus } from "../../../actions/jumpstartRevamped";

const Label = ({ section }) => (
  <Typography variant="subtitle2" color="#838383">
    <Chip label="In Progress" className="orangeStatusColor" />
    {` (${section})`}
  </Typography>
);
function PageHeader(props) {
  const history = useHistory();
  const {
    hidden,
    sectionHeading,
    fetchJsAccountById,
    fetchJsAccountByIdReducer,
    token,
    completedText,
    fetchUserInfoReducer,
    resetJsAccountStatus,
  } = props;
  const { id } = useParams();
  const [businessName, setBusinessName] = useState();
  const [businessUrl, setBusinessUrl] = useState();
  const [lastUpdated, setLastUpdated] = useState();
  const [status, setStatus] = useState("In Progress");
  const [loading, setLoading] = useState(false);
  const [businessUrlWithHttps, setBusinessUrlWithHtps] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [options, setOptions] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("CAMPAIGN_FRAMEWORK_IN_PROGRESS");

  useEffect(async () => {
    if (
      fetchJsAccountByIdReducer.message == "JS_ACCOUNT_BY_ID_RECEIVED" &&
      fetchJsAccountByIdReducer.id == id
    ) {
      setBusinessName(fetchJsAccountByIdReducer.companyName);
      setBusinessUrl(fetchJsAccountByIdReducer.websiteUrl);
      setStatus(fetchJsAccountByIdReducer.status);
      setLastUpdated(formatDate(fetchJsAccountByIdReducer.lastModifiedDate));
    }
  }, [fetchJsAccountByIdReducer]);

  useEffect(() => {
    if (businessUrl != "" && businessUrl != null && businessUrl != undefined) {
      setBusinessUrlWithHtps(
        businessUrl.startsWith("http") ? businessUrl : "https://" + businessUrl
      );
    }
  }, [businessUrl]);

  useEffect(() => {
    if (
      (status.includes("REVIEWER_APPROVED") || status.includes("IN_REVIEW")) &&
      (fetchUserInfoReducer.authority == Authority.REVIEWER ||
        fetchUserInfoReducer.authority == Authority.MANAGER ||
        fetchUserInfoReducer.authority == Authority.ADMIN)
    ) {
      setShow(true);
      if (sectionHeading == "Campaign Framework") {
        setOptions([
          {
            value: "CAMPAIGN_FRAMEWORK_IN_PROGRESS",
            label: Label({ section: "Campaign Framework" }),
          },
        ]);
      } else {
        setOptions([
          {
            value: "CAMPAIGN_FRAMEWORK_IN_PROGRESS",
            label: Label({ section: "Campaign Framework" }),
          },
          {
            value: "CAMPAIGN_STRUCTURE_IN_PROGRESS",
            label: Label({ section: "Campaign Structure" }),
          },
        ]);
      }
    } else {
      setShow(false);
    }
  }, [status, fetchUserInfoReducer.message == "JS_USER_INFO_RECEIVED"]);

  const setStatusColor = (status) => {
    if (status.includes("CREATED")) {
      return "yellowStatusColor";
    } else if (status.includes("IN_PROGRESS")) {
      return "orangeStatusColor";
    } else if (status.includes("IN_REVIEW")) {
      return "blueStatusColor";
    } else if (status.includes("REVIEWER_APPROVED")) {
      return "purpleStatusColor";
    } else if (status == "COMPLETED") {
    }
  };

  const handleBackIconBtn = () => {
    history.push(`/jumpstart-dashboard`);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleRadioChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleStatusChange = async () => {
    setLoading(true);
    const data = await resetJsAccountStatus(token, id, selectedStatus);
    if (data != null) {
      setStatus(data.status);
      setShow(false);
    }
    setLoading(false);
    handleCloseDialog();
    if ((status == AccountStatus.CAMPAIGN_STRUCTURE_IN_REVIEW && selectedStatus == AccountStatus.CAMPAIGN_STRUCTURE_IN_PROGRESS)
      || (status == AccountStatus.CAMPAIGN_FRAMEWORK_IN_REVIEW && selectedStatus == AccountStatus.CAMPAIGN_FRAMEWORK_IN_PROGRESS)) {
    }
    else {
      handleBackIconBtn();
    }
  };

  return (
    <div className="stickyHeader">
      <Grid
        container
        direction="row"
        rowSpacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={9}>
          <JsSectionTypography>
            {loading ? (
              <Skeleton />
            ) : (
              <>
                <IconButton onClick={handleBackIconBtn}>
                  <ArrowBackIcon sx={{ color: "#F58120" }} />
                </IconButton>
                {sectionHeading}:
                <span style={{ color: "#F58120" }}>&nbsp;{businessName}</span>
              </>
            )}
          </JsSectionTypography>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="end"
          alignItems="center"
          xs={3}
        >
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <Grid item>
                <JsSubSectionTypography style={{ paddingRight: "5px" }}>
                  Status:
                </JsSubSectionTypography>
              </Grid>
              <Grid item>
                <Chip
                  label={FormatAccountStatus(status)}
                  className={setStatusColor(status)}
                />
              </Grid>
            </>
          )}
        </Grid>

        {hidden && hidden === "true" ? (
          <JsSubSectionTypography style={{ paddingLeft: "40px" }}>
            {loading ? <Skeleton /> : completedText}
          </JsSubSectionTypography>
        ) : (
          <>
            <Grid item container alignItems="center" xs={8}>
              <Grid item>
                <JsSubSectionTypography style={{ paddingLeft: "4px" }}>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <>
                      {businessUrl == null ||
                        businessUrl == undefined ||
                        businessUrl == "" ? (
                        ""
                      ) : (
                        <a
                          href={businessUrlWithHttps}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IconButton>
                            <LaunchIcon fontSize="small" color="action" />
                          </IconButton>
                        </a>
                      )}

                      {businessUrl}
                    </>
                  )}
                </JsSubSectionTypography>
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={4}>
          {show ? (
            <JsSubSectionTypography
              style={{ float: "right", cursor: "pointer" }}
              onClick={handleOpenDialog}
            >
              {loading ? <Skeleton /> : <>Change Status</>}
            </JsSubSectionTypography>
          ) : (
            ""
          )}
          <ResetStatusDialog
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            sectionHeading={sectionHeading}
            status={status}
            options={options}
            setStatusColor={setStatusColor}
            FormatAccountStatus={FormatAccountStatus}
            handleRadioChange={handleRadioChange}
            handleStatusChange={handleStatusChange}
            loading={loading}
          />
        </Grid>
      </Grid>
    </div>
  );
}
const mapStateToProps = (state) => ({
  fetchJsAccountByIdReducer: state.fetchJsAccountByIdReducer,
  fetchUserInfoReducer: state.fetchUserInfoReducer,
});
const mapDispatchToProps = (dispatch) => ({
  fetchJsAccountById: (token, id) => dispatch(fetchJsAccountById(token, id)),
  resetJsAccountStatus: (token, id, status) =>
    dispatch(resetJsAccountStatus(token, id, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
